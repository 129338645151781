<template>
  <div
    :class="[
      'input',
      {
        'readonly': readonly,
        'disabled': disabled
      }
    ]"
  >
    <label
      v-if="label.length"
      :class="[
        `input__label`,
        { 'required': required }
      ]"
    >
      {{ label }}
      <span
        v-if="required"
        class="input__label--icon"
      >
        *
      </span>
    </label>
    <div :class="[`input__wrapper`, { 'error': !valid}]">
      <input
        ref="input"
        :id="`${id}input`"
        :class="[
          'input__input',
          { 'readonly': readonly }
        ]"
        :value="value"
        v-imask="mask"
        :placeholder="placeholder"
        :type="inputType"
        :readonly="readonly"
        :required="required"
        :autocomplete="inputAutocomplete"
        :rules="validate"
        @accept="onAccept($event)"
        @complete="onComplete"
        @keyup.enter="$emit('enter')"
      />
      <transition name="fade">
        <div
          v-show="value !== ''"
          class="input__icon input__icon--delete"
          v-html="require(`@/assets/img/icons/delete.svg`)"
          @click="clear"
        />
      </transition>
      <div
        v-if="type === 'search'"
        class="input__icon input__icon--search"
        :class="{ 'focus': value && value.length }"
        v-html="require(`@/assets/img/icons/search.svg`)"
      />
      <app-button-tooltip
        v-if="type === 'promocode'"
        class="input__tooltip"
        icon="Tooltip"
        message="Дополнительно начислим бонусы на ваш бонусный счет"
      />
    </div>
    <transition name="expand">
      <div
        v-show="!valid"
        class="input__error"
      >
        <div class="input__error-wrapper">
          <div
            class="input__icon input__icon--attention"
            v-html="require(`@/assets/img/icons/attention.svg`)"
          />
          <span>{{ message }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import IMaskDirective from '@/directives/mask'
import ButtonTooltip from '@/components/UI/buttons/ButtonTooltip'

export default {
  name: 'Input',
  components: {
    'app-button-tooltip': ButtonTooltip
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
      validator (value) {
        return ['text', 'phone', 'code', 'email', 'nominal', 'search', 'promocode'].indexOf(value) !== -1
      }
    },
    value: {
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    wrongCode: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessage: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    currency: {
      required: false,
      default: 'За бонусы'
    }
  },
  data () {
    return {
      id: uuidv4(),
      mask: {
        mask: ''
      },
      max: 1000,
      valid: true,
      message: ''
    }
  },
  computed: {
    inputType () {
      if (this.type === 'email') {
        return 'email'
      } else if (this.type === 'phone') {
        return 'tel'
      } else {
        return 'text'
      }
    },
    inputAutocomplete () {
      if (this.type === 'email') {
        return 'email'
      } else if (this.type === 'phone') {
        return 'tel'
      } else {
        return 'off'
      }
    }
  },
  watch: {
    valid: {
      handler () {
        this.$emit('update:valid', this.valid)
      },
      immediate: true
    },
    focus: {
      handler () {
        if (this.focus && this.$refs.input) {
          this.$refs.input.focus()
        }
      }
    },
    currency: {
      handler () {
        this.setInputBehavior()
      }
    },
    wrongCode () {
      this.validate(this.value)
    }
  },
  mounted () {
    this.setInputBehavior()
    this.$nextTick(function () {
      if (this.focus) {
        this.$refs.input.focus()
      }
      if (this.type !== 'phone') {
        this.$refs.input.value = this.value
        this.$refs.input.dispatchEvent(new Event('input', { bubbles: true }))
      }
    })
  },
  methods: {
    setInputBehavior () {
      this.mask = {
        mask: '*'.repeat(this.max)
      }
      const icon = this.currency === 'За бонусы' ? '₽' : '★'

      switch (this.type) {
        case 'phone':
          this.mask = {
            mask: '+{7} (000) 000-00-00',
            lazy: false
          }
          break
        case 'code':
          this.mask = {
            mask: '00000',
            lazy: true
          }
          break
        case 'email':
          break
        case 'nominal':
          this.mask = {
            mask: [
              { mask: '' },
              {
                mask: `num ${icon}`,
                lazy: false,
                blocks: {
                  num: {
                    mask: Number,
                    min: 0,
                    max: 999999,
                    thousandsSeparator: ' '
                  }
                }
              }
            ]
          }
          break
      }
    },
    validate (value) {
      this.valid = true
      if (this.required && !value) {
        this.message = 'Поле обязательно для заполнения'
        this.valid = false
        return
      }

      switch (this.type) {
        case 'phone':
          if (value.length < 18) {
            this.message = 'Введены не все символы'
            this.valid = false
          }
          break
        case 'code':
          if (value.length < 5) {
            this.message = 'Введены не все символы'
            this.valid = false
          } else if (this.wrongCode) {
            this.message = 'Неверный код из SMS. Повторите ввод'
            this.valid = false
          }
          break
        case 'email':
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            this.message = 'Неверный формат электронной почты'
            this.valid = false
          }
          break
        case 'nominal':
          break
        case 'promocode':
          if (this.wrongCode) {
            this.message = this.errorMessage ? this.errorMessage : 'Промокод не найден, пожалуйста, проверьте указанный промокод или введите другой'
            this.valid = false
          }
          break
      }
    },
    clear () {
      this.$emit('update:value', '')
      this.$refs.input.focus()
    },
    onAccept (e) {
      this.$emit('update:value', e.detail.unmaskedValue)
      this.validate(e.detail.value)
    },
    onComplete () {
      console.log('complete')
    }
  },
  directives: {
    imask: IMaskDirective
  }
}
</script>

<style lang="scss">

</style>
