<template>
  <div
    v-if="active[getCardMark] || isFree"
    class="mark-wrapper"
  >
    <div
      v-if="isFree"
      :class="`mark mark--${size} mark--free`"
    >
      <span>free</span>
    </div>
    <div
      v-if="active[getCardMark]"
      :class="`mark mark--${size} mark--status`"
    >
      <div
        v-if="getCardMark === 'currency'"
        class="mark__icon"
        v-html="require(`@/assets/img/icons/${active[getCardMark]}.svg`)"
      />
      <img :style="{marginRight: '5px', backgroundColor: 'transparent', width: '20px', height: '20px', display: getPostMarkCurrentCertIsUp}" :src="getPostMarkCurrentCert">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Mark',
  props: {
    card: {
      type: Object,
      required: true
    },
    certificate: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 's',
      validator (value) {
        return ['s', 'm'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCardMark',
      'getPostMarkCurrentCert',
      'getPostMarkCurrentCertIs',
      'getPostMarkCurrentCertIsUp'
    ]),
    active () {
      return this.getCardMark === 'currency' ? this.certificate : this.card
    },
    text () {
      return this.getCardMark === 'currency' ? this.active.nominal : this.active.status
    },
    isFree () {
      return +this.certificate.nominal === 0
    }
  }
}
</script>

<style lang="scss">
  .post-icon{
    background-color: 'transparent';
    width: '20px';
    height: '20px';
  }
  .mark-wrapper {
    position: absolute;
    top: -11px;
    right: -5px;
    display: flex;
  }
  .mark {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-left: 5px;
    padding: 0 5px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    color: $colorMarkFont;
    box-shadow: $box-shadow-mark;
    border-radius: 4px;
    box-sizing: border-box;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      svg {
        fill: $star;
      }
    }
    span {
      margin-top: 2px;
    }
    &--s {
      right: -2px;
      min-width: 32px;
      height: 22px;
      font-size: 10px;
      line-height: 22px;
    }
    &--m {
      right: -4px;
      min-width: 40px;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
    }
    &--free {
      background-color: $free;
    }
    &--status {
      background-color: $mark-accent-color;
    }
  }

  @media(min-width: $tablet-width) {
    .mark {
      &--s {
        min-width: 42px;
        height: 30px;
        font-size: 12px;
        line-height: 30px;
      }
    }
  }
</style>
