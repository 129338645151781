<template>
  <div class="button-copy">
    <div
      ref="buttonCopyInput"
      class="button-copy__input"
      v-html="value"
    />
    <input
      ref="input"
      class="button-copy__input-hidden"
      :value="inputHiddenValue"
    />
    <button
      class="button-copy__button"
      :class="{ 'copied': copied }"
      :disabled="copied"
      @click="copy"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonCopy',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data: () => ({
    copied: false
  }),
  computed: {
    buttonText () {
      return this.copied ? 'Код скопирован' : 'Скопировать код'
    },
    inputHiddenValue () {
      return this.value.includes('href') ? this.value.match(/href="([^"]*)/)[1] : this.value
    }
  },
  mounted () {
    this.$refs.buttonCopyInput.style.fontSize = this.getFontSize(this.$refs.buttonCopyInput.textContent.length)
  },
  methods: {
    copy () {
      const testingCodeToCopy = document.querySelector('.button-copy__input-hidden')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()
      try {
        const successful = document.execCommand('copy')
        this.copied = successful
        setTimeout(() => {
          this.copied = false
        }, 1500)
        const msg = successful ? 'successful' : 'unsuccessful'
        console.log('Copying text command was ' + msg)
      } catch (err) {
        console.log('Oops, unable to copy')
      }
    },
    getFontSize (textLength) {
      const baseSize = 30
      if (textLength >= baseSize) {
        textLength = baseSize - 10
      }
      const fontSize = baseSize - textLength
      return `${fontSize}%`
    }
  }
}
</script>

<style lang="scss">
.button-copy {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 1000%;
  &__input {
    margin-right: 20px;
    font-size: 32px;
    font-weight: 700;
    color: $text-primary;
    line-height: 1.3;
  }
  &__input-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
  }
  &__button {
    width: auto;
    height: 36px;
    color: $text-primary;
    font-size: $caption;
    line-height: 1;
    font-weight: 500;
    background-color: transparent;
    white-space: nowrap;
    border: 1px solid $accent-light;
    border-radius: $border-radius;
    outline: none;
    transition: color $transition, box-shadow $transition;
    box-sizing: border-box;
    &:hover {
      box-shadow: inset 0 0 0 1px $accent-light;
      cursor: pointer;
    }
    &.copied {
      color: $placeholder;
      &:hover {
        box-shadow: none;
        cursor: default;
      }
    }
  }
}
</style>
