import global from './common/global'
import cards from './common/cards'

export default {
  namespaced: true,
  modules: {
    global,
    cards
  }
}
