const getDefaultState = () => {
  return {
    sectionData: {}
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getSectionData: (state) => state.sectionData || {}
  },
  mutations: {
    SET_SECTION_DATA (state, data) {
      state.sectionData = data
    }
  },
  actions: {
    async setSectionData ({ commit }, data) {
      commit('SET_SECTION_DATA', data)
    }
  }
}
