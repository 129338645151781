<template>
  <app-button
    :size="size"
    @click="goToPayment"
  >
    {{ text }}
  </app-button>
</template>

<script>
import Button from '@/components/UI/buttons/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'Pay',
  components: {
    'app-button': Button
  },
  props: {
    card: {
      type: Object,
      required: true
    },
    certificate: {
      type: Object,
      required: true
    },
    extended: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getGood',
      'getCurrency'
    ]),
    isFree () {
      return this.certificate.nominal === 0
    },
    isStars () {
      // return this.getCurrency === 'custom' && this.certificate.currency === 'star'
      return this.certificate.currency === 'star'
    },
    text () {
      if (this.isFree) {
        // если бесплатный
        return this.extended ? `Получить ${this.getGood} бесплатно` : 'Получить'
      } else if (this.isStars) {
        // если за звезды
        return this.extended ? 'Получить за звезды' : 'Получить'
      } else {
        // если за бонусы/рубли
        return this.extended ? `Купить ${this.getGood}` : 'Купить'
      }
    },
    size () {
      return this.extended ? 'm' : 's'
    }
  },
  methods: {
    async goToPayment () {
      if (this.isFree || this.isStars || process.env.VUE_APP_PAYMENT_SERVICE_INTERFACE === 'false') {
        // если бесплатный или за звезды
        // или оплата будет производится без интерфейса псервиса и должны получить от пользователя емейл и телефон
        const data = {
          id: this.certificate._id,
          name: this.card.name,
          nominal: this.certificate.nominal,
          subscription: this.certificate.subscription,
          currency: this.certificate.currency
        }
        this.$store.commit('SET_CERTIFICATE_FOR_PAYMENT', data)
        this.$router.push({ name: 'Payment' })
      } else {
        // если за бонусы/рубли
        const data = {
          certificateId: this.certificate._id,
          url: `${document.location.origin}${document.location.pathname}`,
          pmkey: sessionStorage.getItem('pmkey')
        }

        await this.$store.dispatch('pay', data)
      }
    }
  }
}
</script>
