import { server } from './index'

const paymentServiceApi = {
  payment (data) {
    return server.post('/paymentService/pay', { ...data })
  },
  complete (data) {
    return server.post('/paymentService/complete', { ...data })
  }
}

export { paymentServiceApi }
