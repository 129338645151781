<template>
  <div class="main">
    <router-view v-if="getSections"/>
    <app-button-scroll />
  </div>
</template>

<script>
import ResizeObserver from 'resize-observer-polyfill'
import ButtonScroll from '@/components/UI/buttons/ButtonScroll'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    'app-button-scroll': ButtonScroll
  },
  watch: {
    $route: {
      handler () {
        window.parent.postMessage({ type: 'scrollToTop', behavior: 'auto' }, '*')
      }
    }
  },
  async beforeCreate () {
    const payservice = document.getElementById('BMSApp').getAttribute('data-payservice')
    this.$store.commit('SET_PAYSERVICE', payservice)
    this.$store.commit('SET_CONFIG')
    await this.$store.dispatch('getSections') // получаем разделы
    // console.log(this.$store)
  },
  mounted () {
    const block = document.querySelector('body')
    new ResizeObserver((entries, observer) => {
      window.parent.postMessage({ type: 'resize', data: `${block.clientHeight}` }, '*')
    }).observe(block)
  },
  computed: {
    ...mapGetters([
      'getSections'
    ])
  }
}
</script>

<style lang="scss">
.main {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  padding: 30px 0 140px;
  font-family: $body-font-family;
  font-size: 16px;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-primary;
  * {
    font-family: $body-font-family !important;
  }
}

@media(min-width: 1200px) {
  .main {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
