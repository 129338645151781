<template>
  <a
    class="link"
    :class="{ 'disabled': !link.length }"
    :href="link"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'Link',
  props: {
    link: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.link {
  color: $accent;
  font-weight: inherit;
  text-decoration: none;
  transition: color $transition, opacity $transition;

  .link__icon {
    margin-right: 6px;
  }

  &:hover {
    color: $text-primary;
  }

  &.disabled {
    color: $accent;
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
