<template>
  <div class="error__payment">
    <div
      class="error__payment-title"
    >Платеж не был совершен</div>
<!--    <app-payment-stepper-->
<!--      nextStep-->
<!--      phoneComplete-->
<!--      paymentError-->
<!--    />-->
    <div class="error__payment-img">
      <span
        v-html="require('@/assets/img/icons/error-page.svg')"
      />
    </div>
    <div class="error__payment-info">Произошла ошибка при проведении платежа</div>
    <app-button
      @click="goToCatalog"
    >Перейти в каталог</app-button>
  </div>
</template>

<script>
// import PaymentStepper from '@/components/payment/PaymentStepper'
import Button from '@/components/UI/buttons/Button'

export default {
  name: 'PaymentError',
  components: {
    // 'app-payment-stepper': PaymentStepper,
    'app-button': Button
  },
  methods: {
    goToCatalog () {
      window.history.replaceState({}, document.title, window.location.pathname)
      this.$router.push({ name: 'Catalog' })
    }
  }
}
</script>

<style lang="scss">
.error__payment {
  max-width: 513px;
  padding: 0 15px;
  text-align: left;
  &-title {
    font-size: $title;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &-img {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
  &-info {
    width: 180px;
    text-align: center;
    margin: 0 auto 100px;
  }
}

@media (min-width: $tablet-width) {
  .error__payment {
    &-img {
      text-align: left;
    }
    &-info {
      margin: 0 auto 100px 0;
      text-align: left;
      width: 100%;
    }
    button {
      max-width: 240px;
      display: block;
    }
  }
}
</style>
