<template>
  <div
    v-if="status"
    class="loader overlay"
  >
    <div class="loader__block">
      <div class="spinner center">
        <div
          v-for="i in 12"
          :key="i"
          class="spinner-blade"
        />
      </div>
    </div>
  </div>
</template>

<script>/**
 * Компонент прелоадера
 * @view
 * Прелоадер
 * @props
 * status Boolean Статус загрузки
 */

export default {
  name: 'Loader',
  props: {
    status: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .loader {
    width: 100%;
    padding-top: 20px;

    &__block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      svg {
        fill: $accent;
      }
    }
  }

  $spinner-size: 30px !default;
  $spinner-color: $accent !default;
  $spinner-color-button: $text-secondary !default;
  //$overlay-color: black !default;

  @mixin absolute-center {
    /*position: absolute;*/
    /*left: 0;*/
    /*right: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*margin: auto;*/
  }

  .overlay {
    //background: rgba($overlay-color,.9);
    //@include absolute-center;
  }

  .spinner {
    font-size: $spinner-size;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;

    &.center {
      //  @include absolute-center;
    }
  }

  .spinner-blade {
    position: absolute;
    left: .4629em;
    bottom: 0;
    width: .052em;
    height: .2557em;
    border-radius: .5em;
    background-color: transparent;
    transform-origin: center -.2222em;
    animation: spinner-fade 1s infinite linear;

    $animation-delay: 0s;
    $blade-rotation: 0deg;

    &--button {
      animation: spinner-fade-button 1s infinite linear;
    }

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: $animation-delay;
        transform: rotate($blade-rotation);
        $blade-rotation: $blade-rotation + 30;
        $animation-delay: $animation-delay + .083;
      }
    }
  }

  @keyframes spinner-fade {
    0% {
      background-color: $spinner-color;
    }

    100% {
      background-color: transparent;
    }
  }

  @keyframes spinner-fade-button {
    0% {
      background-color: $spinner-color-button;
    }

    100% {
      background-color: transparent;
    }
  }
</style>
