<template>
  <div
    class="catalog-list"
    v-if="getCards.length"
  >
    <app-catalog-item
      v-for="item in getCards"
      :key="item.certificates[0]._id"
      :card="item"
    />
  </div>

  <load-on-scroll
    v-if="getCards.length > 0 && getCards.length < getTotalElements"
    @intersect="changePagination()"
  />

  <app-loader
    :status="getLoading"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/UI/Loader'
import CatalogItem from '@/components/catalog/CatalogItem'
import LoadOnScroll from '@/components/LoadOnScroll'

export default {
  name: 'CatalogList',
  components: {
    'app-loader': Loader,
    'app-catalog-item': CatalogItem,
    'load-on-scroll': LoadOnScroll
  },
  computed: {
    ...mapGetters([
      'getCurrentSection',
      'getLoading'
    ]),
    storePath () {
      return `${this.getCurrentSection.title}/cards`
    },
    getCards () {
      return this.$store.getters[`${this.storePath}/getCards`]
    },
    getTotalElements () {
      return this.$store.getters[`${this.storePath}/getTotalElements`]
    }
  },
  created () {
    if (!this.getCards.length) {
      this.$store.dispatch(`${this.storePath}/searchCards`)
    }
  },
  methods: {
    changePagination () {
      this.$store.dispatch(`${this.storePath}/setPage`)
      this.$store.dispatch(`${this.storePath}/searchCards`)
    }
  }
}
</script>

<style lang="scss">
.catalog-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  grid-gap: 20px 6px;
  grid-auto-rows: minmax(auto, auto);
  padding: 0 15px;
}

@media(min-width: $tablet-width) {
  .catalog-list {
    grid-template-columns: repeat(4, minmax(20%, 1fr));
    grid-gap: 30px;
    grid-auto-rows: minmax(auto, auto);
  }
}

@media(min-width: 1200px) {
  .catalog-list {
    padding: 0;
  }
}
</style>
