<template>
  <div v-if="!this.$route.params.error">
    <app-loader
      :status="!certificate && !getError"
    />
    <div
      class="success__payment"
      v-if="certificate && !getError"
    >
      <div
        class="success__payment-title"
      >Сертификат успешно {{ isFree ? 'получен' : 'оплачен' }}!</div>
<!--      <app-payment-stepper-->
<!--        nextStep-->
<!--        phoneComplete-->
<!--        paymentComplete-->
<!--        :certificate="certificate"-->
<!--      />-->
      <div
        class="success__payment-info"
      >
        <template v-if="isOhta">
          На ваш адрес электронной почты <span>{{getCertificateInfo.email}}</span> направлено подтверждение с промокодом и описание порядка получения подарка.
          <h5 class="success__payment-subtitle">Как воспользоваться?</h5>
          <div v-html="getCertificateInfo.certificate.how_to"></div>
        </template>
        <template v-else>
          На ваш адрес электронной почты <span>{{getCertificateInfo.email}}</span> был отправлен код электронного сертификата для последующей активации.
        </template>
      </div>
      <hr>
      <div
        class="success__payment-img"
      >
        <figure
          v-if="getCertificateInfo.certificate.jpg_url"
          ref="image"
        />
        <img
          v-else
          src="../assets/img/placeholder.png"
          alt="certificate"
        />
      </div>
      <ul>
        <li>Название: <strong v-html="certificate.name" /></li>
        <li>
          Стоимость:
          <strong v-if="isFree">Бесплатно</strong>
          <strong v-else>{{ $filters.sum(certificate.nominal) }} <app-currency :certificate="certificate" /><span
            v-if="certificate.subscription">- {{ certificate.subscription }}</span></strong>
        </li>
        <li v-if="certificate.section !== 'Игры' && certificate.partner">
          Партнер: <strong>{{ certificate.partner }}</strong>
        </li>
        <li v-if="certificate.section === 'Игры' && certificate.platform">
          Платформа: <strong>{{ certificate.platform.join(', ') }}</strong>
        </li>
        <li v-if="certificate.section === 'Игры' && certificate.category">
          Жанр: <strong>{{ certificate.category.join(', ') }}</strong>
        </li>

      </ul>
      <div>Код для активации:</div>
      <app-button-copy
        :value="certificate.activation_code"
      />
      <img
        v-if="certificate?.graph_code"
        :src="certificate.graph_code"
        alt="barcode"
      />
      <div
        v-if="certificate.pin_code"
        class="pin-code"
        v-html="certificate.pin_code"
      />
      <div v-if="certificate.partner_url">Подробности и активация:</div>
      <a
        v-if="certificate.partner_url"
        :href="certificate.partner_url"
        target="_blank"
      >
        {{ certificate.partner_url }}
      </a>
      <app-button
        class="success__payment__button"
        @click="goToCatalog"
      >
        Перейти в каталог
      </app-button>
    </div>
    <app-payment-error
      v-if="getError"
    />
  </div>
  <app-payment-error
    v-else-if="this.$route.params.error === 'payment'"
  />
  <app-purchase-limit-error
    v-else
    :error="JSON.parse($route.params.error)"
    :certificate="JSON.parse($route.params.certificate)"
  />
</template>

<script>
// import PaymentStepper from '@/components/payment/PaymentStepper'
import { mapGetters } from 'vuex'
import ButtonCopy from '@/components/UI/buttons/ButtonCopy'
import Button from '@/components/UI/buttons/Button'
import skeletonMixin from '@/mixins/skeleton.js'
import Loader from '@/components/UI/Loader'
import PaymentError from '@/components/errors/PaymentError'
import Currency from '@/components/UI/config/Currency'
import PurchaseLimitError from '@/components/errors/PurchaseLimitError'
import helpers from '@/helpers/helpers'

export default {
  name: 'Success',
  components: {
    // 'app-payment-stepper': PaymentStepper,
    'app-button-copy': ButtonCopy,
    'app-button': Button,
    'app-loader': Loader,
    'app-payment-error': PaymentError,
    'app-currency': Currency,
    'app-purchase-limit-error': PurchaseLimitError
  },
  data: () => ({
    certificate: null
  }),
  async mounted () {
    if (this.getError) return
    const query = helpers.methods.parseUrlQuery()
    if (query.orderId || this.$route.params.pay) {
      if (query.orderId) {
        // await this.$store.dispatch('cardPaymentConfirm', {
        //   order: query.orderId
        // })
        await this.$store.dispatch('complete', {
          id: query.orderId,
          phone_number: localStorage.getItem('phone') ?? '',
          email: localStorage.getItem('email') ?? ''
        })
      }
      this.certificate = this.getCertificateInfo.certificate
      await this.$nextTick(function () {
        skeletonMixin.methods.load(this.$refs.image, this.getCertificateInfo.certificate.jpg_url)
      })
    } else if (this.$route.params.error) {

    } else {
      await this.$router.push({ name: 'Catalog' })
    }
  },
  computed: {
    ...mapGetters([
      'getCertificateInfo',
      'getError'
    ]),
    isOhta () {
      return process.env.VUE_APP_PROGRAMM === 'OHTA'
    },
    isFree () {
      return +this.certificate.nominal === 0
    }
  },
  methods: {
    goToCatalog () {
      window.history.replaceState({}, document.title, window.location.pathname)
      this.$router.push({ name: 'Catalog' })
    }
  },
  mixins: [skeletonMixin]
}
</script>

<style lang="scss" scoped>
.success__payment {
  max-width: 513px;
  padding: 0 15px;
  text-align: left;
  &-title {
    font-size: $title;
    font-weight: 500;
    margin-bottom: 20px;
  }
  &-info {
    line-height: 1.5;
    margin-bottom: 20px;
    &::v-deep span {
      font-weight: bold;
    }
  }
  &-img {
    max-width: 380px;
    height: auto;
    margin-bottom: 16px;
    box-shadow: $box-shadow-card;
    & img {
      width: 100%;
      display: block;
    }
    & figure {
      display: flex;
    }
  }
  &-code {
    font-weight: 700;
    margin: 10px 0;
  }
  &-subtitle {
    color: $accent-hover;
    font-size: $subtitle;
  }
  .pin-code {
    margin: 10px 0;
  }
  &__button {
    margin-top: 10px;
  }
}
hr {
  background-color: $secondary;
  height: 1px;
  border: none;
  margin-bottom: 20px;
}
ul {
  list-style: none;
  padding: 0;
  margin-bottom: 16px;
}

a {
  font-size: $subtitle;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 20px;
}

@media (min-width: $tablet-width) {
  button {
    max-width: 240px;
    display: block;
  }
}
</style>
