import sectionModule from '@/store/modules/sectionModule'

const getDefaultState = () => {
  return {
    isRegisteredModules: false,
    modules: []
  }
}

const state = getDefaultState()

const mutations = {
  SET_MODULES (state, data) {
    state.modules.push(data)
  },
  SET_REGISTERED_MODULES (state, value) {
    state.isRegisteredModules = value
  }
  // RESET_REGISTER_MODULES (state) {
  //   state.modules.forEach(club => {
  //     this.unregisterModule(club.toString())
  //   })
  //   Object.assign(state, getDefaultState())
  // }
}

const actions = {
  registerModules ({ commit, state }, data) {
    if (!state.isRegisteredModules) {
      data.forEach(section => {
        if (!this.state[section.title]) {
          this.registerModule(section.title, sectionModule)
          commit('SET_MODULES', section)
          this.dispatch(`${section.title}/global/setSectionData`, section) // сохраняем данные раздела в его
          // модуле
          this.dispatch(`${section.title}/cards/setSort`, section.sortFields[0]) // устанавливаем дефолтное
          // значение сортировки
        }
      })
      commit('SET_REGISTERED_MODULES', true)
      return true
    }
  }
}

const getters = {
  isRegisteredModules: state => state.isRegisteredModules
}

export default {
  state,
  mutations,
  actions,
  getters
}
