import { promocodesApi } from '@/api/promocodes'

const getDefaultState = () => ({
  confirmed: '',
  checked: '',
  status: '',
  message: ''
})

export default {
  state: getDefaultState(),
  mutations: {
    'SET_CONFIRMED' (state, data) {
      state.confirmed = data
    },
    'SET_CHECKED' (state, data) {
      state.checked = data
    },
    'SET_STATUS' (state, data) {
      state.status = data
    },
    'SET_MESSAGE' (state, data) {
      state.message = data
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async confirmPromocode ({ commit }, data) {
      commit('SET_CHECKED', data.promocode)
      commit('SET_STATUS', 'loading')
      commit('SET_MESSAGE', '')
      try {
        const res = await promocodesApi.confirm(data)
        commit('SET_CONFIRMED', res.data.promocode)
        commit('SET_STATUS', '')
      } catch (err) {
        commit('SET_STATUS', 'error')
        if (err.response.data.message === 'PROMOCODE_IS_NOT_FOUND') {
          commit('SET_MESSAGE', 'Промокод не найден, пожалуйста, проверьте указанный промокод или введите другой')
        } else if (err.response.data.message === 'PROMOCODE_DOES_NOT_APPLY_TO_THIS_CERTIFICATE') {
          commit('SET_MESSAGE', 'Промокод не действует для данного товара')
        }
        console.log('err', err)
        throw err
      }
    }
  },
  getters: {
    getPromocodeConfirmed: state => state.confirmed,
    getPromocodeChecked: state => state.checked,
    getPromocodeStatus: state => state.status,
    getPromocodeMessage: state => state.message
  }
}
