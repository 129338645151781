import { authApi } from '@/api/auth'

const getDefaultState = () => {
  return {
    authData: {},
    registered: false,
    authError: false
  }
}

export default {
  state: getDefaultState(),
  mutations: {
    'SET_AUTH_DATA' (state, data) {
      state.authData = { ...data }
    },
    'SET_REGISTERED' (state, data) {
      state.registered = data
    }
  },
  actions: {
    async auth ({ commit }, { phone, certId }) {
      commit('SET_LOADING', true)
      try {
        const res = await authApi.auth(phone, certId)
        if (res.data.message === 'NO_ACTUAL_CARD' ||
          res.data.state === 'CLIENT_NOT_FOUND' ||
          res.data.message === 'CLIENT_NOT_FOUND') commit('SET_REGISTERED', false)
        else commit('SET_REGISTERED', true)
        commit('SET_AUTH_DATA', res.data)
        return res
      } catch (err) {
        console.log(err)
        throw err
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  getters: {
    getAuthData: state => state.authData,
    getRegistered: state => state.registered
  }
}
