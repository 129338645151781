<template>
  <div class="loadObserver"></div>
</template>

<script>
export default {
  name: 'LoadOnScroll',
  props: ['options'],
  mounted () {
    const options = this.options || {}
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      }
    }, options)
    this.observer.observe(this.$el)
  },
  unmounted () {
    this.observer.disconnect()
  },
  data () {
    return {
      observer: null
    }
  }
}
</script>

<style scoped>
.loadObserver {
  height: 1px; /* fix intersection observer for safari iframe */
}
</style>
