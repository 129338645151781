import axios from 'axios'

let baseURL
if (process.env.NODE_ENV === 'development') baseURL = 'http://localhost:8085'
if (process.env.VUE_APP_URL) baseURL = process.env.VUE_APP_URL

const token = Buffer.from(`${process.env.VUE_APP_USERNAME}:${process.env.VUE_APP_PASSWORD}`).toString('base64')

const server = axios.create({
  baseURL,
  timeout: 600000,
  headers: {
    Authorization: `Basic ${token}`
  }
})

export { server }
