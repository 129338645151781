import { sectionsApi } from '@/api/sections'
import helpers from '@/helpers/helpers'

const getDefaultState = () => {
  return {
    sections: null,
    currentSection: null
  }
}

export default {
  state: getDefaultState(),
  getters: {
    getSections: state => state.sections,
    getCurrentSection: state => state.currentSection
  },
  mutations: {
    'SET_SECTIONS' (state, data) {
      state.sections = data
    },
    'SET_CURRENT_SECTION' (state, data) {
      state.currentSection = data
    }
  },
  actions: {
    // вызывается 1 раз, при первой загрузке страницы
    getParentQueries ({ getters }) {
      const data = { nominal: {} }
      const query = helpers.methods.parseUrlQuery()
      let queryPrevious = ''

      Object.keys(query).forEach(key => {
        query[key] = query[key] ? query[key].replace(/__/g, ' ') : query[key]
        if (['min', 'max'].indexOf(key) !== -1) {
          data.nominal[key] = +query[key]
        } else {
          if (query[key]) {
            data[key] = query[key]
            queryPrevious = key
          } else if (key.includes('ensp')) {
            data[queryPrevious] += `${key.replace(/ensp/g, ' ')}`
          } else {
            data[queryPrevious] += `&${key.replace(/__/g, ' ')}`
          }
        }
      })

      if (data.pmkey) {
        sessionStorage.setItem('pmkey', data.pmkey)
      }

      this.dispatch(`${getters.getCurrentSection.title}/cards/setFilter`, data) // устанавливаем переданные параметры в
      // фильтр соответствующего модуля
    },
    // вызывается 1 раз, при первой загрузке страницы
    async getSections ({ commit, dispatch }) {
      try {
        const res = await sectionsApi.getSections()
        commit('SET_SECTIONS', res.data) // сохраняем список разделов во vuex

        this.dispatch('registerModules', res.data) // регистрируем модули vuex для разделов

        // устанаваливаем активный раздел, переданный из родительского окна, либо первый раздел по списку
        const query = helpers.methods.parseUrlQuery()
        const currentSection = query.section
          ? res.data.find(el => el.title === query.section)
          : res.data[0]
        commit('SET_CURRENT_SECTION', currentSection)

        dispatch('getParentQueries') // устанавливаем параметры в фильтр из родительского окна
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    // вызывается при изменении активного раздела пользователем
    setCurrentSection ({ commit, dispatch }, data) {
      commit('SET_CURRENT_SECTION', data) // изменяем во vuex текущий раздел
    }
  }
}
