import { server } from './index'

const cardsApi = {
  getSections () {
    return server.get('cards/sections')
  },
  searchFilters (data) {
    return server.post('cards/filters', data)
  },
  searchCardById (id) {
    return server.get(`cards/one/${id}`)
  },
  searchCardIdByProductCode (productCode) {
    return server.get(`cards/code/${productCode}`)
  },
  search (params, body) {
    return server.post('/cards', { ...body }, { params: params })
  }
}

export { cardsApi }
