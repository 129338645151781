<template>
  <div class="select-wrapper">
    <label
      v-if="label.length"
      class="input__label"
    >
      {{ label }}
    </label>
    <div
      class="select"
      :tabindex="0"
      @blur="open = false"
    >
      <div
        :class="[
          `select__input select__input--${type}`,
          { 'open': open }
        ]"
        @click="open = !open"
      >
        <div
          v-show="selected"
          class="select__input-text"
        >
          <template v-if="subscription">
            {{ $filters.sum(selected.nominal) }} ₽
            <template v-if="selected.subscription"> - {{ selected.subscription }}</template>
          </template>
          <span v-else v-html="selected" />
        </div>
        <div
          v-show="!selected"
          class="select__input-text select__input-text--placeholder"
        >
          Не выбрано...
        </div>
        <div
          v-if="selected && clear"
          :class="`select__input-icon select__input-icon--delete`"
          v-html="require(`@/assets/img/icons/delete.svg`)"
          @click="reset"
        />
        <div
          v-if="type !== 'sort'"
          :class="`select__input-icon select__input-icon--arrow`"
          v-html="require(`@/assets/img/icons/arrow.svg`)"
        />
      </div>
      <div
        :class="[
          { 'close': !open },
          `select__menu select__menu--${type}`
        ]"
      >
        <div
          :class="['select__menu-option', { 'active': JSON.stringify(selected) === JSON.stringify(option) }]"
          v-for="(option, i) of options"
          :key="i"
          @click="pick(option)"
        >
          <div class="select__menu-option-text">
            <span v-if="subscription">
              {{ $filters.sum(option.nominal) }}
              <span v-if="option.subscription"> ₽- {{ option.subscription }}</span>
            </span>
            <span v-else v-html="option" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Select',
  props: {
    type: {
      type: String,
      required: false,
      default: 'category',
      validator (value) {
        return ['category', 'nominal', 'sort'].indexOf(value) !== -1
      }
    },
    value: {
      required: true
    },
    options: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    default: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    subscription: {
      type: Boolean,
      required: false,
      default: false
    },
    clear: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      selected: '',
      open: false
    }
  },
  watch: {
    value: {
      handler () {
        this.selected = this.value
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    if (this.default) {
      this.$watch('options', () => {
        if (this.options && this.options.length) {
          this.selected = this.options[0]
          this.$emit('update:value', this.selected)
        }
      }, { immediate: true })
    }
  },
  methods: {
    pick (option) {
      this.selected = option
      this.open = false
      this.$emit('update:value', option)
    },
    reset () {
      this.selected = ''
      this.open = false
      this.$emit('update:value', this.selected)
    }
  }
}
</script>

<style lang="scss">

</style>
