<template>
  <div
    :class="`item item--${getCardType}`"
    @click="goToCardFullClickable(card.id)"
  >
    <div
      class="item__img"
      @click="goToCard(card.id)"
    >
      <figure v-if="card.jpg_url" ref="image" />
      <img v-else src="../../assets/img/placeholder.png" alt="certificate" class="image">
      <app-mark
        :card="card"
        :certificate="currentCertificate"
        size="s"
      />
    </div>
    <div class="item__info">
      <div>
        <div class="item__info-title" v-html="card.name" />
        <div v-if="getCardType === 'default'" class="item__info-descr">
          <div
            v-if="currentCertificate.nominal === 0"
            class="item__info-price"
          >
            Бесплатно
          </div>
          <div
            v-else
            class="item__info-price"
          >
            {{ $filters.sum(currentCertificate.nominal) }} ₽
            <span
              v-if="currentCertificate.subscription"
            >
              - {{ currentCertificate.subscription }}
            </span>
          </div>
          <div
            v-if="card.section !== 'Игры'"
            class="item__info-partner"
          >
            {{ card.partner }}
          </div>
          <div
            v-if="card.section === 'Игры' && card.category"
            class="item__info-partner"
          >
            <strong>Жанр: </strong>
            <span>{{ card.category.join(', ') }}</span>
          </div>
          <div
            v-if="card.section === 'Игры' && card.platform"
            class="item__info-partner"
          >
            <strong>Платформа: </strong>
            <span>{{ card.platform.join(', ') }}</span>
          </div>
          <div
            v-if="card.benefit"
            class="item__info-partner"
          >
            <strong>Выгода: </strong>
            <span>{{ card.benefit.join(', ') }}</span>
          </div>
          <div
            v-if="currentCertificate.bonus"
            class="item__info-bonus"
          >
          <img :style="{ width: '20px', height: '20px', marginRight: '5px', backgroundColor: 'transparent', display: getPostMarkCurrentCertIs }" :src="getPostMarkCurrentCert"> {{ getRewardTitle }} {{ currentCertificate.bonus }}
          </div>
          <app-select
            v-if="card.certificates.length > 1"
            type="nominal"
            v-model:value="currentCertificate"
            :options="card.certificates"
            subscription
          />
          <div class="item__info-buttons">
            <app-pay-button
              :card="card"
              :certificate="currentCertificate"
            />
            <app-button
              type="flat"
              @click="goToCard(card.id)"
            >
              Подробнее
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Button from '@/components/UI/buttons/Button'
import Select from '@/components/UI/selects/Select'
import skeletonMixin from '@/mixins/skeleton.js'
import Mark from '@/components/UI/config/Mark'
import PayButton from '@/components/UI/buttons/pay/Pay'

export default {
  name: 'CatalogItem',
  components: {
    'app-button': Button,
    'app-select': Select,
    'app-mark': Mark,
    'app-pay-button': PayButton
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentCertificate: {}
  }),
  computed: {
    ...mapGetters([
      'getCardType',
      'getProgramm',
      'getRewardTitle',
      'getPostMarkCurrentCert',
      'getPostMarkCurrentCertIs',
      'getPostMarkCurrentCertIsUp'
    ])
  },
  mounted () {
    this.currentCertificate = { ...this.$props.card.certificates[0] }
    skeletonMixin.methods.load(this.$refs.image, this.card.jpg_url)
  },
  methods: {
    goToCard (id) {
      this.$router.push(`/${id}`)
    },
    goToCardFullClickable (id) {
      if (this.getProgramm !== 'ohta') return
      this.$router.push(`/${id}`)
    }
  },
  mixins: [skeletonMixin]
}
</script>

<style lang="scss">
.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: $border-radius;
  cursor: pointer;
  transition: box-shadow $transition;
  &__img {
    width: 100%;
    height: calc(100vw / 3.5);
    position: relative;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    /*transition: .3s;*/

    img {
      object-fit: cover;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }
  }
  &__info {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 10px 10px 16px;
    background: $good-background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    &>div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &-descr {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    &-title {
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 7px;
    }
    &-price {
      font-weight: 500;
      margin-bottom: 5px;
      line-height: 1.2;
    }
    &-partner {
      font-weight: 500;
      font-size: $caption;
      color: $text-secondary;
      line-height: 1.25;
      margin-bottom: 6px;
      strong {
        font-weight: 500;
        color: $text-primary;
      }
    }
    &-bonus {
      font-size: $caption;
      font-weight: 500;
      line-height: 1.25;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
    }
    .select-wrapper {
      margin-bottom: 10px;
    }
    &-buttons {
      margin-top: auto;
    }
  }
  .button--fill {
    margin-bottom: 10px;
  }
  .button--flat {
    display: block;
    margin: 0 auto;
    font-size: $caption;
    color: $accent;
    font-weight: 500;
  }
  &--preview {
    box-shadow: $box-shadow;
    .item__info {
      background: $primary;
    }
  }
  &:hover {
    box-shadow: $box-shadow-card;
  }
}

@media(min-width: $tablet-width) {
  .item {
    &__img {
      height: calc(100vw / 7.4);
    }
  }
}

@media(min-width: 1200px) {
  .item {
    &__img {
      height: calc(1200px / 6.7);
    }
    &__info {
      &-buttons {
        display: flex;
        align-items: center;
        .button--fill {
          width: 110px;
          margin-bottom: 0;
          margin-right: 10px;
        }
        .button--flat {
          margin: 0;
        }
      }
    }
  }
}
</style>
