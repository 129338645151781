import { server } from './index'

const paymentApi = {
  payment (data) {
    return server.post('/payment', { ...data })
  },
  cardPaymentConfirm (data) {
    return server.post('/payment/confirm/card', { ...data })
  },
  bonusPaymentConfirm (data) {
    return server.post('payment/confirm/bonus', { ...data })
  }
}

export { paymentApi }
