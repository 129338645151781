<template>
  <div class="payment-confirm">
<!--    информация о балансе -->
    <div v-if="!isFree && getPaymentType !== 'ONLY_CURRENCY'">
      <!--    зарегистрирован, оплата звездами -->
      <div
        v-if="getRegistered && isStar"
        class="payment-confirm__bonus"
      >
        <div class="payment-confirm__bonus-title">Ваш баланс: {{ $filters.sum(getAuthData.balance) }} <app-currency
          :certificate="certificate" /></div>
      </div>
      <!--    зарегистрирован, оплата бонусами -->
      <div
        v-if="getRegistered && isBonus"
        class="payment-confirm__bonus"
      >
        <div class="payment-confirm__bonus-title">Ваш баланс: {{ $filters.sum(getAuthData.balance) }} <app-currency
          :certificate="certificate" /></div>
      </div>
      <!--    зарегистрирован, оплата бонусами и рублями -->
      <div
        v-else-if="getRegistered && !isStar && !isBonus"
        class="payment-confirm__bonus"
      >
        <div class="payment-confirm__bonus-title">Доступно бонусов: {{ $filters.sum(getAuthData.balance) }}</div>
        <span>{{ bonusInfo }}</span>
        <app-button-group
          v-if="balance"
          v-model:value="bonusPay"
          :disabled="cardPay"
        />
        <div
          v-if="bonusPay && balance"
          class="payment-confirm__bonus-subtitle"
        >Без начисления бонусов</div>
        <div
          v-else
          class="payment-confirm__bonus-subtitle"
        >Начислим бонусы при оплате картой</div>
      </div>
      <!--    не зарегистрирован, оплата звездами -->
      <div
        v-else-if="!getRegistered && isStar"
        class="payment-confirm__not-registered"
      >
        <div class="payment-confirm__not-registered-title">Регистрация не пройдена</div>
        <div>
          <p>Вы не зарегистрированы в программе лояльности.</p>
        </div>
      </div>
      <!--    не зарегистрирован, оплата бонусами -->
      <div
        v-else-if="!getRegistered && isBonus"
        class="payment-confirm__not-registered"
      >
        <div class="payment-confirm__not-registered-title">Регистрация не пройдена</div>
        <div>
          <p>Вы не зарегистрированы в программе лояльности.</p>
        </div>
      </div>
      <!--    не зарегистрирован, оплата картой -->
      <div
        v-else-if="!getRegistered && !isStar && !isBonus && getPaymentType !== 'ONLY_CURRENCY'"
        class="payment-confirm__not-registered"
      >
        <div class="payment-confirm__not-registered-title">Регистрация не пройдена. Продолжить покупку без бонусов?</div>
        <div v-if="getProgramm === 'cppk'">
          <p>Вы не зарегистрированы в программе лояльности САКВОЯЖ. Вы можете оплатить {{ getGood }} картой без начисления бонусов.</p>
          <p>Чтобы мы смогли начислить бонусы, пожалуйста, <a href="https://bonusbag.ru/how" target="_blank">зарегистрируйтесь</a> до покупки.</p>
          <p>Процесс покупки нужно будет начать заново после регистрации.</p>
        </div>
        <div v-if="getProgramm === 'ohta'">
          <p>Вы не зарегистрированы в программе лояльности. Вы можете оплатить {{ getGood }} картой без начисления бонусов.</p>
          <p>Чтобы мы смогли начислить бонусы, пожалуйста, <a href="https://google.com" target="_blank">зарегистрируйтесь</a> до покупки.</p>
          <p>Процесс покупки нужно будет начать заново после регистрации.</p>
        </div>
      </div>
    </div>
    <!--    -->

    <!--    промокод -->
    <div
      v-if="getRegistered && getProgramm === 'cppk' && !isStar && ((isBonus && cost <= 0) || !isBonus) && !isFree"
      class="payment-confirm__promocode"
    >
      <app-input
        type="promocode"
        label="Введите промокод"
        v-model:value="promocode"
        v-model:valid="promocodeValid"
        :error-message="getPromocodeMessage"
        :wrong-code="promocode === getPromocodeChecked && getPromocodeStatus === 'error'"
        :disabled="cardPay"
      />
      <app-button
        :disabled="
          !promocode.length ||
          promocode === getPromocodeChecked ||
          getPromocodeStatus === 'loading' ||
          cardPay"
        :loading="getPromocodeStatus === 'loading'"
        @click="confirmPromocode()"
      >
        {{ promocode && promocode === getPromocodeChecked && getPromocodeStatus === '' ? 'Промокод применен' :
        'Применить промокод' }}
      </app-button>
    </div>
    <!--    -->

    <!--    сумма доплаты -->
    <div
      v-if="!isStar && !isBonus && !isFree"
      class="payment-confirm__title"
    >
      Итого к оплате картой: {{ $filters.sum(cost) }} ₽
    </div>
    <div
      v-if="!isStar && !isBonus && !isFree"
      class="payment-confirm__type"
    >
      <span v-html="require('@/assets/img/icons/Mastercard.svg')" />
      <span v-html="require('@/assets/img/icons/Visa.svg')" />
      <span v-html="require('@/assets/img/icons/Mir.svg')" />
<!--      <span v-if="isApplePay && getPayservice" v-html="require('@/assets/img/icons/apple_pay.svg')" />           -->
<!--      <span v-if="!isIos() && getPayservice" v-html="require('@/assets/img/icons/google-pay-mark_800.svg')" />   -->
    </div>
    <div v-if="isFree">
      <div
        class="payment-confirm__title payment-confirm__title--without-margin"
      >
        Итого к оплате: {{ $filters.sum(cost) }} ₽
      </div>
      <div class="payment-confirm__subtitle">Без начисления бонусов</div>
    </div>
    <!--    -->

    <!--    ввод почты и чекбокс принятия условий -->
    <app-input
      v-if="!((isStar || isBonus) && cost > 0)"
      type="email"
      :value="email"
      v-model:value="email"
      v-model:valid="emailValid"
      :label="`Электронная почта для отправки${!(isStar || isBonus) ? ' чека и' : '' } сертификата`"
      focus
      required
      :disabled="cardPay"
    />
    <div
      v-if="!((isStar || isBonus) && cost > 0)"
      class="payment-confirm__checkbox"
    >
      <app-checkbox
        :value="checkbox"
        v-model:value="checkbox"
        :disabled="cardPay"
      />
      <span v-if="getProgramm === 'cppk'">Я принимаю <a href="https://bonusbag.ru/shpplc" target="_blank">условия покупки</a> электронных сертификатов и выражаю свое <a
        href="https://bonusbag.ru/dtgrmnt" target="_blank">Согласие с использованием персональных данных</a> в целях обработки настоящего обращения и согласие с <a
        href="https://bonusbag.ru/dtplc" target="_blank">Политикой в отношении обработки и защиты персональных данных</a>
      </span>
      <span v-if="getProgramm === 'whitelabel'">Я принимаю <a href="https://bonusbag.ru/shpplc" target="_blank">условия покупки</a> электронных сертификатов и выражаю свое <a
        href="https://bonusbag.ru/dtgrmnt" target="_blank">Согласие с использованием персональных данных</a>
      </span>
      <span v-if="getProgramm === 'ohta'">Я принимаю <a href="https://google.com" target="_blank">условия покупки</a> электронных сертификатов и выражаю свое <a
        href="https://google.com" target="_blank">Согласие с использованием персональных данных</a> в целях обработки настоящего обращения и согласие с <a
        href="https://google.com" target="_blank">Политикой в отношении обработки и защиты персональных данных</a>
      </span>
    </div>
    <!--    -->

    <!--    выбор оплаты за бонусы/звезды или рублями  -->
    <div
      v-if="!getConfirm && !cardPay"
      class="payment-confirm__pay"
    >
      <app-button
        v-if="isFree"
        :disabled="!valid"
        @click="payment('bonus', false)"
      >
        <span>Получить бесплатно</span>
      </app-button>

      <app-button
        v-else-if="isStar && cost <= 0"
        :disabled="!valid"
        @click="payment('star', false)"
      >
        <span>Получить за звезды</span>
      </app-button>

      <app-button
        v-else-if="isBonus && cost <= 0"
        :disabled="!valid"
        @click="payment('bonus', false)"
      >
        <span>Получить за бонусы</span>
      </app-button>

      <div v-else-if="!isStar && !isBonus">
        <app-button
          :disabled="!valid"
          @click="payment('bonus', false)"
        >
          <span v-if="cost > 0">Перейти к оплате</span>
          <span v-else>Получить за бонусы</span>
        </app-button>
      </div>
    </div>
    <!--    -->

    <!--    ввод смс-кода -->
    <app-input
      v-if="getConfirm"
      type="code"
      :value="smsCode"
      v-model:value="smsCode"
      label="Код из SMS"
      :wrong-code="getWrongCode"
      focus
      required
    />
    <div
      class="payment-confirm__timer"
      v-if="getConfirm"
    >
      <span v-if="!resend">Повторная отправка сообщения возможна через <strong>0 : {{ time }}</strong></span>
      <span
        v-else
        class="payment-confirm__resending"
        @click="payment('bonus', true)"
      >
        Отправить код повторно
      </span>
    </div>
    <!--    -->

    <!--    выбор способа оплаты рублями -->
    <div
      v-show="cardPay"
      class="payment-confirm__pay-card"
    >
      <app-button
        @click="payment('card', false)"
      >
        Оплатить картой
      </app-button>
      <!--      <app-apple-pay-->
      <!--        v-if="getPayservice"-->
      <!--        :total-price="cost"-->
      <!--        @save="payment('apple', false, false)"-->
      <!--        @pay="payment('apple', false, true, $event)"-->
      <!--        @is-apple-pay="isApplePay = $event"-->
      <!--      />-->
      <app-google-pay
        v-if="!isIos() && getPayservice"
        :total-price="cost"
        @save="payment('google', false, false)"
        @pay="payment('google', false, true, $event)"
      />
    </div>
    <!--    -->

    <!--    ошибка -->
    <div
      v-if="getError"
      class="error"
    >
      {{ getErrorMessage }}
    </div>
    <!--    -->

    <!--    прелоадер -->
    <app-loader
      v-if="getLoading && !getError"
      :status="getLoading"
    />
    <!--    -->
  </div>
</template>

<script>
import Input from '@/components/UI/inputs/Input'
import ButtonGroup from '@/components/UI/buttons/ButtonGroup'
import Checkbox from '@/components/UI/inputs/Checkbox'
import Button from '@/components/UI/buttons/Button'
import { mapGetters, mapActions } from 'vuex'
import Loader from '@/components/UI/Loader'
import Currency from '@/components/UI/config/Currency'
// import GooglePay from '@/components/UI/buttons/pay/Google'
// import ApplePay from '@/components/UI/buttons/pay/Apple'

export default {
  name: 'PaymentConfirm',
  components: {
    'app-input': Input,
    'app-button-group': ButtonGroup,
    'app-checkbox': Checkbox,
    'app-button': Button,
    'app-loader': Loader,
    'app-currency': Currency
    // 'app-google-pay': GooglePay
    // 'app-apple-pay': ApplePay
  },
  props: {
    certificate: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    bonusPay: true,
    cardPay: false,
    email: '',
    checkbox: false,
    emailValid: false,
    smsCode: '',
    send: false,
    resend: false,
    time: 59,
    timer: null,
    promocode: '',
    promocodeValid: false,
    isApplePay: false
  }),
  created () {
    if (this.getPaymentType === 'ONLY_CURRENCY') {
      this.bonusPay = false
    }
  },
  computed: {
    ...mapGetters([
      'getAuthData',
      'getRegistered',
      'getConfirm',
      'getPaymentInfo',
      'getLoading',
      'getError',
      'getErrorMessage',
      'getCurrency',
      'getProgramm',
      'getGood',
      'getCertificateInfo',
      'getWrongCode',
      'getPromocodeConfirmed',
      'getPromocodeChecked',
      'getPromocodeStatus',
      'getPromocodeMessage',
      'getPayservice',
      'getPaymentType'
    ]),
    balance () {
      return this.getAuthData.balance !== '0.00' && this.getAuthData.balance !== 0
    },
    bonusInfo () {
      if (+(this.getAuthData.balance) >= this.certificate.nominal) {
        return `Списать ${this.$filters.sum(this.certificate.nominal)} бонусов?`
      } else if (+(this.getAuthData.balance) < this.certificate.nominal && this.balance) {
        return `Списать ${this.$filters.sum(this.getAuthData.balance)} бонусов?`
      } else {
        return 'Списание невозможно'
      }
    },
    valid () {
      if (this.email.length && this.emailValid && this.checkbox) {
        if (this.getProgramm === 'cppk' && !this.isFree) {
          if (this.getRegistered) {
            return this.promocodeValid && this.getPromocodeStatus !== 'loading'
          } else return true
        } else {
          return true
        }
      } else return false
    },
    cost () {
      if (!this.getRegistered) return this.certificate.nominal
      if (+(this.getAuthData.balance) > this.certificate.nominal && this.bonusPay) return '0'
      if (!this.bonusPay) return this.certificate.nominal
      return +(this.certificate.nominal - +(this.getAuthData.balance)).toFixed(2)
    },
    isStar () {
      return this.getCurrency === 'custom' && this.certificate.currency === 'star'
    },
    isBonus () {
      return this.certificate.currency === 'bonus'
    },
    isFree () {
      return +this.certificate.nominal === 0
    }
  },
  watch: {
    async smsCode () {
      if (this.smsCode.length === 5) {
        localStorage.setItem('confirmCode', this.smsCode)
        await this.$store.dispatch('bonusPaymentConfirm', {
          rrn: this.getPaymentInfo.rrn,
          confirmCode: this.smsCode
        })
        if (!this.getCertificateInfo.certificate) {
          this.cardPay = true
        } else {
          await this.$router.push({ name: 'Success', params: { pay: 'bonus' } })
        }
      }
    },
    time () {
      if (this.time === 0) {
        this.stopTimer()
        this.resend = true
        this.time = 59
      }
    },
    '$route' () {
      this.resetState()
    }
  },
  methods: {
    ...mapActions([
      'resetState'
    ]),
    async payment (type, rrn = false, order = false, token) {
      localStorage.setItem('email', this.email)

      if (process.env.VUE_APP_PAYMENT_SERVICE_INTERFACE === 'false') {
        const data = {
          certificateId: this.certificate.id,
          url: `${document.location.origin}${document.location.pathname}`,
          pmkey: sessionStorage.getItem('pmkey'),
          email: this.email,
          phone: localStorage.getItem('phone'),
          promocode: this.getPromocodeConfirmed
        }
        await this.$store.dispatch('pay', data)
        return
      }
      // показываем выбор оплаты если пользователь не зарегистрирован
      if (!this.cardPay && !this.getRegistered) {
        this.cardPay = true
        return
      }

      // отправляем rrn если было списание/начисление бонусов и сертификат уже ждет оплаты в холде
      if (this.cardPay && this.getRegistered) {
        rrn = true
      }

      const data = {
        certificateId: this.certificate.id,
        email: this.email,
        phone: localStorage.getItem('phone'),
        spendBonus: this.bonusPay,
        promocode: this.getPromocodeConfirmed,
        type,
        token: token,
        url: `${document.location.origin}${document.location.pathname}`,
        pmkey: sessionStorage.getItem('pmkey')
      }

      if (rrn) data.rrn = this.getPaymentInfo.rrn

      if (order) data.order = this.getPaymentInfo.order

      await this.$store.dispatch('payment', data)

      if (this.getConfirm) {
        this.startTimer()
        this.emailValid = false
      } else {
        if (!this.getPaymentInfo.certificate) {
          this.cardPay = true
        } else {
          await this.$router.push({ name: 'Success', params: { pay: 'bonus' } })
        }
      }
    },
    startTimer () {
      this.resend = false
      this.timer = setInterval(() => {
        this.time--
      }, 1000)
    },
    stopTimer () {
      clearInterval(this.timer)
    },
    async confirmPromocode () {
      const data = {
        certificateId: this.certificate.id,
        promocode: this.promocode
      }

      await this.$store.dispatch('confirmPromocode', data)
    },
    isIos () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
        (navigator.userAgent.includes('Mac'))
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-confirm {
  &__bonus,
  &__not-registered {
    margin-bottom: 20px;
    padding: 16px 15px;
    box-shadow: $box-shadow;
    font-weight: 500;
    text-align: left;
    border-radius: $border-radius;
    &-title {
      font-size: $subtitle;
      /*margin-bottom: 6px;*/
    }
    & .button-group {
      margin: 10px 0;
    }
    &-subtitle {
      color: $text-secondary;
      font-weight: 400;
      font-size: $caption;
    }
  }
  &__not-registered {
    &-title {
      color: $accent;
      font-size: $subtitle;
      font-weight: 500;
    }
    & div {
      font-weight: 400;
      line-height: 1.5;
    }
  }
  &__promocode {
    max-width: 380px;
    margin-bottom: 20px;
  }
  &__title {
    font-size: $subtitle;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px;
    &--without-margin {
      margin-bottom: 0;
    }
  }
  &__subtitle {
    font-size: $caption;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
    color: $text-secondary;
  }
  & .input {
    max-width: 380px;
    margin-bottom: 20px;
  }
  &__checkbox {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    & span {
      text-align: left;
      color: $text-secondary;
      font-size: $caption;
      line-height: 1.25;
      margin-left: 6px;
    }
  }
  &__type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
    & span {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }
  &__timer {
    font-size: $caption;
    color: $text-secondary;
    text-align: left;
    margin-bottom: 10px;
    & strong {
      color: $text-primary;
    }
  }
  &__resending {
    cursor: pointer;
    color: $accent;
  }
  &__pay {
    &-card {
      & > * {
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
.loader {
  margin: 10px auto 20px;
}
.error {
  margin-top: 12px;
  color: $accent;
  font-weight: 500;
  margin-bottom: 15px;
  border: 1px solid $accent;
  padding: 5px 0;
}

@media (min-width: $tablet-width) {
  .payment-confirm__checkbox {
    max-width: 380px;
  }
}
</style>
