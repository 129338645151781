<template>
  <div class="payment">
    <div class="payment__header">
      <app-button-arrow
        @click="goBack"
      />
      <span>{{ isFree ? 'Получение' : 'Покупка' }} {{ getGood }}а</span>
    </div>
    <app-payment-stepper
      :nextStep="nextStep"
      :phoneComplete="complete"
      :certificate="currentCertificate"
    />
    <div
      class="payment__info"
      v-if="nextStep && getRegistered && !isFree && getPaymentType !== 'ONLY_CURRENCY'"
    >
      <div class="payment__info-title">Как оплатить?</div>
      <span v-if="isStar">Оплата {{ getGood }}а осуществляется 100% звездами без возможности дополнительной оплаты.</span>
      <span v-if="isBonus">Данный {{ getGood }} можно получить только за бонусы. Оплата {{ getGood }}а банковской картой не осуществляется.</span>
      <span v-else>Вы можете оплатить {{ getGood }} 100% бонусами или картой. При недостаточном количестве бонусов возможна доплата с банковской карты.</span>
    </div>
    <div class="payment__certificate">
      <div>Название: <span v-html="currentCertificate.name" /></div>
      <div>Стоимость:
        <span v-if="isFree">Бесплатно</span>
        <span v-else>{{ currentCertificate.nominal }} <app-currency :certificate="currentCertificate" /><span
          v-if="currentCertificate.subscription &&
      currentCertificate.subscription !== 'null'">- {{
        currentCertificate.subscription }}</span></span>
      </div>
    </div>
    <app-phone-confirm
      v-if="!nextStep"
      :certId="currentCertificate.id"
      :certificate="currentCertificate"
      @nextstep="nextStep = $event"
      @phone-complete="complete = $event"
    />
    <app-payment-confirm
      v-if="nextStep"
      :certificate="currentCertificate"
    />
  </div>
</template>

<script>
import ButtonArrow from '@/components/UI/buttons/ButtonArrow'
import { mapGetters } from 'vuex'
import PhoneConfirm from '@/components/payment/PhoneConfirm'
import PaymentConfirm from '@/components/payment/PaymentConfirm'
import PaymentStepper from '@/components/payment/PaymentStepper'
import Currency from '@/components/UI/config/Currency'

export default {
  name: 'Payment',
  components: {
    'app-payment-stepper': PaymentStepper,
    'app-phone-confirm': PhoneConfirm,
    'app-payment-confirm': PaymentConfirm,
    'app-button-arrow': ButtonArrow,
    'app-currency': Currency
  },
  data: () => ({
    nextStep: false,
    currentCertificate: {},
    complete: false
  }),
  mounted () {
    if (Object.keys(this.getCertificateForPayment).length) this.currentCertificate = this.getCertificateForPayment
    else this.$router.push({ name: 'Catalog' })
  },
  computed: {
    ...mapGetters([
      'getAuthData',
      'getRegistered',
      'getGood',
      'getCurrency',
      'getPaymentType',
      'getCertificateForPayment'
    ]),
    isStar () {
      return this.getCurrency === 'custom' && this.currentCertificate.currency === 'star'
    },
    isBonus () {
      return this.currentCertificate.currency === 'bonus'
    },
    isFree () {
      return +this.currentCertificate.nominal === 0
    }
  },
  methods: {
    goBack () {
      this.$store.commit('SET_CONFIRM', false)
      this.$router.back()
    }
  }
}
</script>

<style lang="scss">
.payment {
  max-width: 513px;
  padding: 0 15px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    & .button-arrow:hover svg {
      fill: $accent;
    }
    & div {
      margin-right: 10px;
    }
    & span {
      font-size: $title;
      font-weight: 500;
    }
  }
  &__info {
    padding: 16px 15px;
    margin-bottom: 20px;
    background: linear-gradient(180deg, $accent-light 0%, $accent 194.38%);
    box-shadow: $box-shadow;
    text-align: left;
    color: $primary;
    border-radius: $border-radius;
    &-title {
      font-size: $title;
      font-weight: 500;
      margin-bottom: 6px;
    }
  }
  &__certificate {
    text-align: left;
    margin-bottom: 20px;
    & div {
      font-size: $font-size-root;
      color: $text-secondary;
      & span {
        font-weight: 500;
      }
    }
  }
}
</style>
