<template>
  <div
    :class="`filter filter--${getProgramm}`"
    v-scroll="onScroll"
  >
    <div
      class="filter__header"
      @click="active = !active"
    >
      <div class="filter__header-img">
        <span v-html="require('@/assets/img/icons/Filter.svg')"/>
        <span>Фильтр</span>
      </div>
      <app-button-arrow
        top
        :bottom="!active"
      />
    </div>

    <div
      ref="filterSettings"
      :class="[
        `filter__settings filter__settings--${getProgramm}`,
        {
          'active': active,
          'filter__settings--fields-length-4': getCurrentSection.filterFields.length === 4
        }
      ]"
    >
      <div
        v-if="getCurrentSection.filterFields.includes('currency')"
        class="filter__settings-field filter__settings-field--currency"
      >
        <app-select
          label="Покупка товаров"
          v-model:value="filter.currency"
          :options="getFilters.currency"
          clear
          default
        />
      </div>
      <div
        v-if="getCurrentSection.filterFields.includes('platform')"
        class="filter__settings-field filter__settings-field--platform"
      >
        <app-select
          label="Платформа"
          v-model:value="filter.platform"
          :options="getFilters.platforms"
          clear
        />
      </div>
      <div
        v-if="getCurrentSection.filterFields.includes('benefit')"
        class="filter__settings-field filter__settings-field--benefit"
      >
        <app-select
          label="Выгода"
          v-model:value="filter.benefit"
          :options="getFilters.benefits"
          clear
        />
      </div>
      <div class="filter__settings-field filter__settings-field--price">
        <span>Диапазон стоимости</span>
        <div>
          <app-input
            type="nominal"
            v-model:value="filter.nominal.min"
            placeholder="От"
            :currency="filter.currency"
          />
          <span>-</span>
          <app-input
            type="nominal"
            v-model:value="filter.nominal.max"
            placeholder="До"
            :currency="filter.currency"
          />
        </div>
      </div>
      <div
        v-if="getCurrentSection.filterFields.includes('partner')"
        class="filter__settings-field filter__settings-field--partner"
      >
        <app-select
          label="Партнер"
          v-model:value="filter.partner"
          :options="getFilters.partners"
          clear
        />
      </div>
      <div
        v-if="getCurrentSection.filterFields.includes('category')"
        class="filter__settings-field filter__settings-field--category"
      >
        <app-select
          :label="getCurrentSection.title === 'Игры' ? 'Жанр' : 'Категория'"
          v-model:value="filter.category"
          :options="getFilters.categories"
          clear
        />
      </div>
      <div
        v-if="getCurrentSection.filterFields.includes('subcategory')"
        class="filter__settings-field filter__settings-field--subcategory"
      >
        <app-select
          label="Подкатегория"
          v-model:value="filter.subcategory"
          :options="getFilters.subcategories"
          clear
        />
      </div>
      <div
        v-if="getCurrentSection.filterFields.includes('name')"
        class="filter__settings-field filter__settings-field--name"
      >
        <app-select
          label="Название"
          v-model:value="filter.name"
          :options="getFilters.names"
          clear
        />
      </div>
      <div class="filter__settings-field filter__settings-field--buttons">
        <app-button
          type="flat"
          @click="reset()"
        >
          Сбросить
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonArrow from '@/components/UI/buttons/ButtonArrow'
import Input from '@/components/UI/inputs/Input'
import Select from '@/components/UI/selects/Select'
import Button from '@/components/UI/buttons/Button'
import { mapGetters } from 'vuex'

export default {
  name: 'CatalogFilter',
  components: {
    'app-button': Button,
    'app-button-arrow': ButtonArrow,
    'app-input': Input,
    'app-select': Select
  },
  data: () => ({
    filter: {
      nominal: {}
    },
    active: true,
    clear: false,
    firstLoad: true,
    debounceInterval: 700,
    lastTimeout: '',
    windowWidth: window.innerWidth
  }),
  computed: {
    ...mapGetters([
      'getSections',
      'getCurrentSection',
      'getProgramm'
    ]),
    storePath () {
      return `${this.getCurrentSection.title}/cards`
    },
    getFilter () {
      return this.$store.getters[`${this.storePath}/getFilter`]
    },
    getFilters () {
      return this.$store.getters[`${this.storePath}/getFilters`]
    }
  },
  watch: {
    active: {
      handler () {
        if (this.active === true) {
          setTimeout(() => {
            if (this.active === true) {
              this.$refs.filterSettings.style.overflow = 'visible'
            }
          }, 400)
        } else if (this.$refs.filterSettings) {
          this.$refs.filterSettings.style.overflow = 'hidden'
        }
      },
      immediate: true
    },
    filter: {
      handler () {
        if (this.clear) {
          this.clear = false
          this.$store.dispatch(`${this.storePath}/setFilter`)
          this.$store.dispatch(`${this.storePath}/searchCards`)
        } else if (!this.firstLoad) {
          if (this.filter.nominal && (this.filter.nominal.min || this.filter.nominal.max)) this.debounce()
          else this.submit()
        }
        this.firstLoad = false
      },
      deep: true
    },
    windowWidth: {
      handler () {
        this.active = this.windowWidth >= 1024 + 1
      },
      immediate: true
    }
  },
  created () {
    // if (!Object.keys(this.getFilters).length) {
    //   this.$store.dispatch(`${this.storePath}/searchFilters`)
    // }
    this.filter = JSON.parse(JSON.stringify(this.getFilter))
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    debounce () {
      if (this.lastTimeout !== '') {
        clearTimeout(this.lastTimeout)
      }
      this.lastTimeout = setTimeout(() => {
        this.submit()
      }, this.debounceInterval)
    },
    submit () {
      this.$store.dispatch(`${this.storePath}/setFilter`, this.filter)
      this.$store.dispatch(`${this.storePath}/searchCards`)
    },
    reset () {
      this.filter = {
        nominal: {}
      }
      this.clear = true
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    onScroll (ngos) {
      // if (typeof window === 'undefined') return
      // this.active = false
    }
  }
}
</script>

<style lang="scss">
.filter {
  width: 100%;
  background: $filter-background-color;
  padding: 30px 15px 20px;
  box-sizing: border-box;
  border-radius: $border-radius;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $filter-secondary-color;
    cursor: pointer;
    & .button-arrow:hover svg {
      fill: $filter-secondary-color;
    }
    &-img {
      display: flex;
      align-items: center;
      & svg {
        fill: $filter-secondary-color;
      }
      & span {
        display: flex;
        align-items: center;
        font-size: $title;
        font-weight: 500;
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
  &__settings {
    display: grid;
    grid-template-columns: repeat(1, minmax(50%, 1fr));
    grid-auto-rows: auto;
    align-items: flex-end;
    max-height: 0;
    margin-top: 10px;
    overflow: hidden;
    transition: all .4s ease-in;
    &.active {
      max-height: 350px;
      margin: 23px 0 10px;
      animation: overflow .4s ease-in;
      //overflow: visible;
    }
    &-field {
      margin-top: 10px;
      &--price {
        & > span {
          font-size: $caption;
          line-height: 1;
          margin-bottom: 3px;
        }
        div {
          display: flex;
          align-items: center;
          span {
            margin: 0 4px;
          }
        }
      }
      &--buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        // margin-top: 20px;
        margin-top: 20px;
        .button--fill {
          width: 150px;
        }
      }
    }
    &--ohta {
      .filter__settings-field {
        &--currency {
          order: 1;
        }
        &--price {
          order: 2;
        }
        &--partner {
          order: 3;
        }
        &--category {
          order: 4;
        }
        &--subcategory {
          order: 5;
        }
        &--buttons {
          order: 6;
        }
      }
    }
    &--cppk,
    &--whitelabel {
      .filter__settings-field {
        &--currency {
          order: 1;
        }
        &--platform {
          order: 2;
        }
        &--benefit {
          order: 6;
        }
        &--price {
          order: 8;
        }
        &--partner {
          order: 5;
        }
        &--category {
          order: 3;
        }
        &--subcategory {
          order: 4;
        }
        &--name {
          order: 7;
        }
        &--buttons {
          order: 9;
        }
      }
    }
  }
}

@keyframes overflow {
  0%, 99% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
}

@media(min-width: $tablet-width) {
  .filter {
    width: auto;
    left: 0;
    padding: 30px;
    &__settings {
      grid-template-columns: repeat(2, minmax(45%, 1fr));
      grid-gap: 10px 30px;
      &-field--buttons {
        justify-content: flex-start;
        .button--fill {
          width: 180px;
          margin-right: 30px;
        }
      }
    }
  }
}

@media(min-width: $desktop-width) {
  .filter {
    width: auto;
    left: 0;
    padding: 30px;
    &__settings {
      &-field {
        margin-top: 0;
        &--buttons {
          flex-direction: column;
          align-items: center;
          margin-top: 10px;
          .button--fill {
            margin-right: 0;
          }
          .button--flat {
            margin-top: 10px;
          }
        }
      }
    }
    &--cppk,
    &--whitelabel {
      .filter__settings {
        grid-template-columns: 1fr 1fr 1fr 180px;
        &--fields-length-4 {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          .filter__settings-field {
            &--buttons {
              margin-top: 0;
              grid-column-start: 1;
              grid-column-end: 5;
            }
          }
        }
      }
    }
    &--ohta {
      .filter__settings {
        grid-template-columns: 1fr 1.3fr 1fr 1fr 100px;
      }
    }
  }
}
</style>
