<template>
  <div
    class="autocomplete select"
    :tabindex="0"
    v-click-outside="clickOutside"
  >
    <app-input
      :value="value"
      type="search"
      placeholder="Начните вводить название..."
      @update:value="update"
    />
    <div
      :class="[
        { 'close': !open },
        `select__menu select__menu--category`
      ]"
    >
      <div
        class="select__menu-option"
        v-for="(option, i) of foundOptions"
        :key="i"
        @click="pick(option)"
      >
        <div class="select__menu-option-text">
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/UI/inputs/Input'

export default {
  name: 'Autocomplete',
  components: {
    'app-input': Input
  },
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data: () => ({
    foundOptions: [],
    open: false
  }),
  methods: {
    update (e) {
      this.$emit('update:value', e)
      this.foundOptions = this.options.filter(el => el.toUpperCase().includes(e.toUpperCase()))
      this.open = this.foundOptions.length
    },
    pick (option) {
      this.$emit('update:value', option)
      this.open = false
    },
    clickOutside () {
      this.open = false
    }
  }
}
</script>

<style lang="scss">

</style>
