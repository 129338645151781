import { paymentApi } from '@/api/payment'
import router from '../../router'

const getDefaultState = () => ({
  paymentInfo: {},
  needSmsConfirm: false,
  wrongCode: false,
  certificateInfo: {},
  certificateForPayment: {}
})

export default {
  state: getDefaultState(),
  mutations: {
    'SET_PAYMENT_INFO' (state, data) {
      state.paymentInfo = { ...data }
    },
    'SET_CONFIRM' (state, data) {
      state.needSmsConfirm = data
    },
    'SET_WRONG_CODE' (state, data) {
      state.wrongCode = data
    },
    'SET_CERTIFICATE_INFO' (state, data) {
      state.certificateInfo = { ...data }
    },
    'SET_CERTIFICATE_FOR_PAYMENT' (state, data) {
      state.certificateForPayment = data
    }
  },
  actions: {
    async payment ({ commit }, data) {
      commit('SET_ERROR', false)
      commit('SET_LOADING', true)
      try {
        const res = await paymentApi.payment(data)

        if (res.data.formUrl) window.location.replace(res.data.formUrl)
        else commit('SET_PAYMENT_INFO', res.data)

        if (res.data.needSmsConfirm) commit('SET_CONFIRM', true)

        if (res.data.certificate) {
          commit('SET_CERTIFICATE_INFO', res.data)
          router.push({ name: 'Success', params: { pay: 'bonus' } })
        }
      } catch (err) {
        if (err.response.data.message === 'PAYMENT_IS_NOT_CONFIRMED') {
          router.push({ name: 'Success', params: { error: 'payment' } })
        } else {
          commit('SET_ERROR', true)
          commit('SET_ERROR_MESSAGE', err.response.data.message)
        }
        console.log('err', err)
        throw err
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async bonusPaymentConfirm ({ commit, state }, data) {
      try {
        commit('SET_WRONG_CODE', false)
        const res = await paymentApi.bonusPaymentConfirm(data)
        commit('SET_CERTIFICATE_INFO', res.data)
        commit('SET_CONFIRM', false)
      } catch (err) {
        console.log(err)
        commit('SET_WRONG_CODE', true)
        throw err
      }
    },
    async cardPaymentConfirm ({ commit }, data) {
      try {
        const res = await paymentApi.cardPaymentConfirm(data)
        commit('SET_CERTIFICATE_INFO', res.data)
      } catch (err) {
        commit('SET_ERROR', true)
        console.log(err)
        throw err
      }
    }
  },
  getters: {
    getPaymentInfo: state => state.paymentInfo,
    getConfirm: state => state.needSmsConfirm,
    getWrongCode: state => state.wrongCode,
    getCertificateInfo: state => state.certificateInfo,
    getCertificateForPayment: state => state.certificateForPayment
  }
}
