<template>
  <div
    class="phone-confirm"
  >
    <hr>
    <div>
      {{ text }}
    </div>
    <app-input
      type="phone"
      v-model:value="phoneNumber"
      label="Номер телефона"
      placeholder="+7 (999) 999-99-99"
      focus
      required
    />
    <app-loader
      :status="getLoading"
    />
  </div>
</template>

<script>
import Input from '@/components/UI/inputs/Input'
import { mapGetters } from 'vuex'
import Loader from '@/components/UI/Loader'

export default {
  name: 'PhoneConfirm',
  components: {
    'app-input': Input,
    'app-loader': Loader
  },
  props: {
    certId: {
      type: String,
      required: true
    },
    certificate: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    phoneNumber: ''
  }),
  computed: {
    ...mapGetters([
      'getAuthData',
      'getLoading',
      'getPaymentType'
    ]),
    isFree () {
      return +this.certificate.nominal === 0
    },
    text () {
      return this.isFree || this.getPaymentType === 'ONLY_CURRENCY'
        ? (process.env.VUE_APP_PAYMENT_TEXT || 'Вам необходимо подтвердить номер для идентификации.')
        : 'Для получения актуального баланса по бонусному счету, вам необходимо подтвердить номер для идентификации.'
    }
  },
  watch: {
    async phoneNumber () {
      if (this.phoneNumber.length === 11) {
        try {
          const res = await this.$store.dispatch('auth', { phone: this.phoneNumber, certId: this.certId })
          console.log('res', res)
          localStorage.setItem('phone', this.phoneNumber)
          this.$emit('nextstep', true)
          this.$emit('phone-complete', true)
        } catch (e) {
          console.log('e', e.response.data)
          if (e && e.response && e.response.data.statusCode === 1) {
            this.$router.push({
              name: 'SuccessPayment',
              params: {
                error: JSON.stringify(e.response.data),
                certificate: JSON.stringify(this.certificate)
              }
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.phone-confirm {
  & > div {
    text-align: left;
    margin-bottom: 20px;
  }
  & .input {
    margin-bottom: 10px;
  }
}
hr {
  background-color: $secondary;
  height: 1px;
  border: none;
  margin-bottom: 20px;
}

@media (min-width: $tablet-width) {
  .input {
    max-width: 380px;
  }
}
</style>
