<template>
  <div
    :class="[
      `button-arrow`,
      { 'button-arrow--left': left },
      { 'button-arrow--top': top },
      { 'button-arrow--bottom': bottom }
    ]"
    v-html="require(`@/assets/img/icons/arrow.svg`)"
  />
</template>

<script>
export default {
  name: 'ButtonArrow',
  props: {
    left: {
      type: Boolean,
      required: false,
      default: false
    },
    top: {
      type: Boolean,
      required: false,
      default: false
    },
    bottom: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.button-arrow {
  width: 20px;
  height: 18px;
  line-height: 1;
  transition: transform $transition;
  svg {
    fill: $filter-secondary-color;
    cursor: pointer;
    transition: fill $transition;
  }
  &--left {
    transform: rotate(0deg);
  }
  &--top {
    transform: rotate(90deg);
  }
  &--bottom {
    transform: rotate(-90deg);
  }
  &:hover {
    svg {
      fill: $text-primary;
    }
  }
}
</style>
