<template>
  <!-- <span
    v-if="getCurrency === 'custom' && Object.keys(certificate).length"
    class="currency__icon"
    v-html="require(`@/assets/img/icons/${certificate.currency}.svg`)"
  /> -->
  <!-- <span v-if="getCurrency === 'default'"> ₽</span> -->
  <span
    v-if="getCurrency === 'custom' && certificate.currency && certificate.currency === 'star'"
    class="currency__icon"
    v-html="require(`@/assets/img/icons/${certificate.currency}.svg`)"
  />

  <span v-else> ₽</span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Currency',
  props: {
    certificate: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getCurrency'
    ])
  }
}
</script>

<style lang="scss">
.currency__icon {
  margin-left: 2px;
  svg {
    fill: $star;
  }
}
</style>
