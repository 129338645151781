import { createRouter, createWebHashHistory } from 'vue-router'
import Catalog from '../views/Catalog.vue'
import CertificateCard from '../views/CertificateCard'
import Payment from '../views/Payment'
import Success from '../views/Success'
import UIKit from '../views/UI-Kit'
import helpers from '@/helpers/helpers'
import { cardsApi } from '@/api/cards'

const routes = [
  {
    path: '/',
    name: 'Catalog',
    component: Catalog,
    beforeEnter: async (to, from, next) => {
      const query = helpers.methods.parseUrlQuery()
      if (query.orderId) {
        next({ name: 'Success' })
      } else if (query.productCode) {
        try {
          const id = await cardsApi.searchCardIdByProductCode(query.productCode)
          next({ name: 'CertificateCard', params: { id: id.data.id, productCode: query.productCode } })
        } catch (e) {
          window.history.replaceState({}, document.title, window.location.pathname)
          next()
        }
      } else next()
    }
  },
  {
    path: '/:id',
    name: 'CertificateCard',
    component: CertificateCard,
    props: true
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    path: '/ui-kit',
    name: 'UI-Kit',
    component: UIKit
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
