<template>
  <div class="button-tooltip">
  <div
    ref="button"
    class="button-tooltip__icon"
    aria-describedby="tooltip"
    v-html="require(`@/assets/img/icons/${icon}.svg`)"
    :style="`width: ${size[0]}px; height: ${size[1]}px;`"
    :tabindex="0"
    @mouseenter="show"
    @focus="show"
    @mouseleave="hide"
    @blur="hide"
  />
  <transition name="fade">
    <div
      v-show="visible"
      ref="tooltip"
      class="button-tooltip__message"
      role="tooltip"
    >
      {{ message }}
      <div
        class="button-tooltip__arrow"
        data-popper-arrow
      />
    </div>
  </transition>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'

export default {
  name: 'ButtonTooltip',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Array,
      required: false,
      default () {
        return [22, 22]
      }
    },
    message: {
      type: String,
      required: true
    }
  },
  data: () => ({
    popper: null,
    visible: false
  }),
  beforeUnmount () {
    this.destroy()
  },
  methods: {
    show () {
      this.visible = true
      this.create()
    },
    hide () {
      this.visible = false
    },
    create () {
      this.popper = createPopper(this.$refs.button, this.$refs.tooltip, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 9]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 15
            }
          }
        ]
      })
    },
    destroy () {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    }
  }
}
</script>

<style lang="scss">
.button-tooltip {
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    svg {
      fill: $text-secondary;
      transition: fill $transition;
    }
    &:hover {
      svg {
        fill: $accent;
      }
    }
  }
  &__message {
    width: 170px;
    padding: 5px 6px;
    text-align: left;
    font-size: $caption;
    line-height: $font-size-root;
    font-weight: 500;
    color: $primary;
    background-color: $tooltip;
    box-shadow: $box-shadow-error;
    &[data-popper-placement^='top'] > .button-tooltip__arrow {
      bottom: -4px;
    }

    &[data-popper-placement^='bottom'] > .button-tooltip__arrow {
      top: -8px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid $tooltip;
    }

    &[data-popper-placement^='left'] > .button-tooltip__arrow {
      right: -4px;
    }

    &[data-popper-placement^='right'] > .button-tooltip__arrow {
      left: -4px;
    }
  }
  &__arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $tooltip;
    z-index: -1;
  }
}
</style>
