<template>
  <div
    class="certificate__card"
    v-if="getCurrentCard && !isLoading"
    @click="noop"
  >
    <div class="certificate__card-header">
      <app-button-arrow
        @click="goBack"
      />
      <span v-html="getCurrentCard.name" />
    </div>
    <div class="certificate__card-view">
      <div class="certificate__card-action">
        <div class="certificate__card-img">
          <figure v-if="getCurrentCard.jpg_url" ref="image" />
          <img v-else src="../assets/img/placeholder.png" alt="certificate" class="image">
          <!-- <app-mark
            :card="getCurrentCard"
            :certificate="currentCertificate"
            size="m"
          /> -->
        </div>
        <div>
          <span class="certificate__card-title">{{ getCurrentCard.partner }}</span>
          <app-select
            v-if="getCurrentCard.certificates?.length > 1"
            label="Выберите сертификат"
            :options="getCurrentCard.certificates"
            v-model:value="currentCertificate"
            default
            subscription
          />
          <div v-else class="certificate__card-purchase">
            <span>
              <span
                v-if="getCurrency === 'default'"
                class="certificate__card-purchase__icon"
                v-html="require('@/assets/img/icons/Price.svg')"
              />
              <span
                v-if="getCurrency === 'custom' && currentCertificate.currency"
                class="certificate__card-purchase__icon"
                v-html="require(`@/assets/img/icons/${currentCertificate.currency}-outline.svg`)"
              />
              <span
                v-if="currentCertificate.nominal === 0"
                class="certificate__card-purchase__price"
              >
                Бесплатно
              </span>
              <span
                v-else
                class="certificate__card-purchase__price"
              >
                {{ $filters.sum(currentCertificate.nominal) }}<app-currency :certificate="currentCertificate" />
                <span
                  v-if="currentCertificate.subscription"
                >
                  -{{ currentCertificate.subscription }}
                </span>
              </span>
            </span>
          </div>
          <app-pay-button
            :card="getCurrentCard"
            :certificate="currentCertificate"
            extended
          />
        </div>
      </div>
      <div class="certificate__card-description">
        <div class="certificate__card-tabs">
          <span
            :class="['tab', { 'active' : tabActive }]"
            @click="tabActive = true"
          >Описание</span>
          <span
            :class="['tab', { 'active' : !tabActive }]"
            @click="tabActive = false"
          >Как воспользоваться?</span>
        </div>
        <div class="certificate__card-info">
          <div
            v-show="tabActive"
            class="certificate__card-tab certificate__card-tab--descr"
          >
            <ul class="certificate__card-tab-list">
<!--              Платформа-->
              <li
                v-if="getCurrentCard.section === 'Игры'
                  && getCurrentCard.platform"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/console.svg')"
                />
                <div class="float">
                  <span>Платформа:&nbsp;</span>
                  <strong>{{ getCurrentCard.platform.join(', ') }}</strong>
                </div>
              </li>
<!--              Стоимость-->
              <li>
                <span
                  v-if="getCurrency === 'default'"
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Price.svg')"
                />
                <span
                  v-if="getCurrency === 'custom' && currentCertificate.currency"
                  class="info__icon"
                  v-html="require(`@/assets/img/icons/${currentCertificate.currency}-outline.svg`)"
                />
                <div class="float">
                  <span>Стоимость:&nbsp;</span>
                  <strong>{{ $filters.sum(currentCertificate.nominal) }}
                    <app-currency :certificate="currentCertificate" />
                    {{ currentCertificate.subscription ? '- ' + currentCertificate.subscription : '' }}
                  </strong>
                </div>
              </li>
<!--              Жанр, Категория-->
              <li
                v-if="getCurrentCard.section !== 'Сертификаты'"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Category.svg')"
                />
                <div class="float">
                  <span>{{ getCurrentCard.section === 'Игры' ? 'Жанр' : 'Категория' }}:&nbsp;</span>
                  <strong>
                    {{ Array.isArray(getCurrentCard.category)
                    ? getCurrentCard.category.join(', ')
                    : getCurrentCard.category }}
                  </strong>
                </div>
              </li>
<!--              Подкатегория-->
              <li
                v-if="getCurrentCard.subcategory"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/subcategory.svg')"
                />
                <div class="float">
                  <span>Подкатегория:&nbsp;</span>
                  <strong>{{ getCurrentCard.subcategory }}</strong>
                </div>
              </li>
              <li v-if="getCurrentCard.benefit">
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Percent.svg')"
                />
                <div class="float">
                  <span>Выгода:&nbsp;</span>
                  <strong>
                    {{ Array.isArray(getCurrentCard.benefit)
                    ? getCurrentCard.benefit.join(', ')
                    : getCurrentCard.benefit }}
                  </strong>
                </div>
              </li>
<!--              Бонусы-->
              <li
                v-if="currentCertificate.bonus"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Percent.svg')"
                />
                <div class="float">
                  <span>{{getRewardTitle}}:&nbsp;</span>
                  <strong>{{ currentCertificate.bonus }}</strong>
                </div>
              </li>
<!--              Партнер-->
              <li
                v-if="getCurrentCard.partner
                  && (getCurrentCard.section !== 'Игры'
                  && getCurrentCard.section !== 'Сертификаты')"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Partner.svg')"
                />
                <div class="float">
                  <span>Партнер:&nbsp;</span>
                  <strong>{{ getCurrentCard.partner }}</strong>
                </div>
              </li>
<!--              Сайт партнера-->
              <li
              v-if="getCurrentCard.partner_url
                  && (getCurrentCard.section !== 'Сертификаты')"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Share.svg')"
                />
                <div class="float">
                  <span>Сайт Партнера:&nbsp;</span>
                  <a
                    target="_blank"
                    :href="getCurrentCard.partner_url"
                  >
                    <strong>{{ partnerUrl }}</strong>
                  </a>
                </div>
              </li>
<!--              Duration-->
              <li
                v-if="currentCertificate.duration
                  && (getCurrentCard.section === 'Сертификаты')"
                class="certificate__card-tab-list-duration"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/duration.svg')"
                />
                <div class="float">
                  <strong>
                    {{ currentCertificate.duration }}
                    <app-tooltip v-if="getProgramm !== 'whitelabel'"
                      icon="Tooltip"
                      message="При использовании сертификата Вы также можете использовать карту лояльности партнера для получения дополнительной скидки или бонусов"
                    />
                  </strong>
                </div>
              </li>
<!--              Срок активации-->
              <li
                v-if="currentCertificate.activation_date
                  && (getCurrentCard.section === 'Сертификаты')"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/activation.svg')"
                />
                <div class="float">
                  <span>Срок активации:&nbsp;</span>
                  <strong>
                    {{ currentCertificate.activation_date }}
                    <app-tooltip
                      icon="Tooltip"
                      message="Срок после покупки, в течение которого сертификат становится активным (доступным к использованию)"
                    />
                  </strong>
                </div>
              </li>
<!--              Срок действия-->
              <li
                v-if="currentCertificate.to_date"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Validity.svg')"
                />
                <div class="float">
                  <span>Срок действия:&nbsp;</span>
                  <strong>
                    {{ formatDate(currentCertificate.to_date) }}
                    <app-tooltip
                      icon="Tooltip"
                      message="До этой даты Вы можете использовать код у Партнера"
                    />
                  </strong>
                </div>
              </li>
<!--              Срок действия-->
              <li
                v-if="currentCertificate.expires_in"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Validity.svg')"
                />
                <div class="float">
                  <span>Срок действия:&nbsp;</span>
                  <strong>
                    {{ formatDate(currentCertificate.expires_in) }}
                    <app-tooltip
                      icon="Tooltip"
                      message="До этой даты Вы можете использовать код у Партнера"
                    />
                  </strong>
                </div>
              </li>
<!--              Срок активации-->
              <li
                v-if="currentCertificate.activation_date
                  && (getCurrentCard.section !== 'Сертификаты')"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/activation.svg')"
                />
                <div class="float">
                  <span>Срок активации:&nbsp;</span>
                  <strong>
                    {{ currentCertificate.activation_date }}
                    <app-tooltip
                      icon="Tooltip"
                      message="Срок после покупки, в течение которого сертификат становится активным (доступным к использованию)"
                    />
                  </strong>
                </div>
              </li>
<!--              Duration-->
              <li
                v-if="currentCertificate.duration
                  && (getCurrentCard.section !== 'Сертификаты')"
                class="certificate__card-tab-list-duration"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/duration.svg')"
                />
                <div class="float">
                  <strong>
                    {{ currentCertificate.duration }}
                    <app-tooltip  v-if="getProgramm !== 'whitelabel'"
                      icon="Tooltip"
                      message="При использовании сертификата Вы также можете использовать карту лояльности партнера для получения дополнительной скидки или бонусов"
                    />
                  </strong>
                </div>
              </li>
<!--              Ограничение-->
              <li
                v-if="getCurrentCard.section === 'Игры'
                  && currentCertificate.limitation"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/warning.svg')"
                />
                <div class="float">
                  <span>Ограничение:&nbsp;</span>
                  <strong>{{ currentCertificate.limitation }}</strong>
                </div>
              </li>
<!--              Сайт партнера-->
              <li
                v-if="getCurrentCard.partner_url
                  && (getCurrentCard.section === 'Сертификаты')"
              >
                <span
                  class="info__icon"
                  v-html="require('@/assets/img/icons/Share.svg')"
                />
                <div class="float">
                  <span>Сайт Партнера:&nbsp;</span>
                  <a
                    target="_blank"
                    :href="getCurrentCard.partner_url"
                  >
                    <strong>{{ partnerUrl }}</strong>
                  </a>
                </div>
              </li>
            </ul>
            <div
              class="certificate__card-info-text"
              v-html="description"
            />
          </div>
          <div
            v-show="!tabActive"
            class="certificate__card-tab certificate__card-tab--how"
          >
            <div v-html="currentCertificate.how_to ?? getCurrentCard.how_to" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-loader
    :status="isLoading"
    v-else
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonArrow from '@/components/UI/buttons/ButtonArrow'
import Select from '@/components/UI/selects/Select'
import ButtonTooltip from '@/components/UI/buttons/ButtonTooltip'
import Loader from '@/components/UI/Loader'
import skeletonMixin from '@/mixins/skeleton.js'
// import Mark from '@/components/UI/config/Mark'
import Currency from '@/components/UI/config/Currency'
import PayButton from '@/components/UI/buttons/pay/Pay'

export default {
  name: 'CertificateCard',
  components: {
    'app-button-arrow': ButtonArrow,
    'app-select': Select,
    'app-tooltip': ButtonTooltip,
    'app-loader': Loader,
    // 'app-mark': Mark,
    'app-currency': Currency,
    'app-pay-button': PayButton
  },
  data () {
    return {
      tabActive: true,
      tooltipActive: false,
      insDate: '',
      currentCertificate: {},
      isLoading: false,
      description: ''
    }
  },
  props: {
    productCode: {
      type: String,
      default: null
    }
  },
  async mounted () {
    this.currentCertificate = {}
    this.isLoading = true
    await this.$store.dispatch(`${this.storePath}/searchCardById`, this.$route.params)
    await this.$store.dispatch(`${this.storePath}/searchGoodsByCardId`, { id: this.$route.params.id })
    this.isLoading = false
    if (this.productCode) {
      this.currentCertificate = this.getCurrentCard.certificates.find(el => {
        return el.product_code === this.productCode
      })
    } else {
      this.currentCertificate = this.getCurrentCard.certificates[0]
    }
    this.description = this.calcDescription(this.currentCertificate)
    this.insDate = this.setInsDate(this.getCurrentCard.insDate)
    this.$nextTick(() => skeletonMixin.methods.load(this.$refs.image, this.getCurrentCard.jpg_url))
  },
  watch: {
    currentCertificate (newCertificate) {
      this.description = this.calcDescription(newCertificate)
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentSection',
      'getCurrency',
      'getProgramm',
      'getRewardTitle',
      'getPostMarkCurrentCert',
      'getPostMarkCurrentCertIs'
    ]),
    storePath () {
      return `${this.getCurrentSection.title}/cards`
    },
    getCurrentCard () {
      return this.$store.getters[`${this.storePath}/getCurrentCard`]
    },
    getCurrentGoods () {
      return this.$store.getters[`${this.storePath}/getCurrentGoods`]
    },
    toDate () {
      if (!this.currentCertificate.to_date) return
      return this.currentCertificate.to_date.split('T').shift()
    },
    partnerUrl () {
      return this.getCurrentCard.partner_url ? (new URL(this.getCurrentCard.partner_url)).host : ''
    }
  },
  methods: {
    setInsDate (val) {
      return val
        .substr(0, 10)
        .replace(/-/g, '.')
        .split('.')
        .reverse()
        .join('.')
    },
    goBack () {
      window.history.replaceState({}, document.title, window.location.pathname)
      this.$router.push({ name: 'Catalog' })
    },
    formatDate (date) {
      if (!date) return
      return date.split('T').shift().split('-').reverse().join('.')
    },
    noop () { return null },
    calcDescription (certificate) {
      return Object.values(this?.getCurrentGoods || {})?.find?.(good => good?.nominal === certificate?.nominal)?.description ?? certificate?.description ?? this?.getCurrentCard?.description
    }
  },
  mixins: [skeletonMixin]
}
</script>

<style lang="scss">
.certificate__card {
  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 0 15px;
    & .button-arrow svg {
      fill: $text-primary;
    }
    span {
      margin-left: 10px;
      font-size: $title;
      font-weight: 500;
    }
  }
  &-action {
    margin-bottom: 30px;
    padding: 0 15px;
  }
  &-img {
    width: 100%;
    height: calc(100vw / 1.77);
    position: relative;
    margin-bottom: 10px;
    box-shadow: $box-shadow-card;
    border-radius: $border-radius;
    img {
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
  &-title {
    display: none;
    margin-bottom: 30px;
    font-size: $title;
    font-weight: 500;
    text-align: left;
  }
  &-purchase {
    width: 100%;
    // background: $accent-light;
    background-color: $primary;
    // opacity: .7;
    font-size: $subtitle;
    font-weight: 500;
    // color: $primary;
    color: $text-primary;
    padding: 13px 0;
    margin-bottom: 10px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__icon {
      margin-right: 8px;
      svg {
        // fill: $primary;
        fill: $money-icon-color;
        path,
        rect {
          fill: $money-icon-color;
        }
      }
    }
  }
  & .select-wrapper {
    margin-bottom: 10px;
  }
  &-description {
    width: 100%;
    background: $secondary;
    border-radius: $border-radius;
    overflow: hidden;
  }
  &-tabs {
    display: flex;
    align-items: center;
    height: 46px;
    .tab {
      display: inline-block;
      line-height: 46px;
      font-size: $subtitle;
      font-weight: 500;
      color: $primary;
      background: $inactive-tab-background;
      flex-grow: 1;
      cursor: pointer;
    }
    .tab.active {
      color: $text-primary;
      background: $secondary;
      font-weight: 500;
    }
  }
  &-tab {
    div {
      text-align: left;
    }
    &-list {
      list-style: none;
      text-align: left;
      padding: 0;
      margin: 0 0 30px;
      & li {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        font-size: $font-size-root;
        margin-bottom: 16px;
        .nowrap {
          display: inline-flex;
          flex-wrap: nowrap;
        }
        & > div {
          display: inline-flex;
        }
        & > div.float {
          display: inline-block;
          width: 100%;
          strong {
            display: block;
          }
          & > span {
            float: left;
            height: 20px;
          }
          .button-tooltip {
            display: inline-block;
            vertical-align: middle;
          }
        }
        strong {
          display: inline-flex;
        }
        &:last-child {
          margin-bottom: 0;
        }
        & .info__icon {
          width: 22px;
          margin-right: 17px;
          display: flex;
          justify-content: center;
          flex-shrink: 0;
          text-align: left;
          & svg {
            fill: $accent;
          }
        }
        & a {
          font-weight: 500;
        }
      }
      &-duration {
        // width: 300px;
      }
    }
  }
  &-info {
    padding: 30px 17px;
    &-text {
      background: transparent!important;
    }
  }
}

@media(min-width: $tablet-width) {
  .certificate__card {
    &-header {
      padding: 0 15px;
    }
    &-action {
      display: grid;
      grid-template-columns: 1.1fr 0.9fr;
      grid-auto-rows: minmax(auto, auto);
      grid-gap: 0px 20px;
      padding: 0 20px;
      & > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: justify;
      }
    }
    &-img {
      height: calc(100vw / 3.2);
      margin-bottom: 0;
    }
    &-title {
      display: block;
    }
    &-tabs {
      .tab {
        width: 50%;
      }
    }
    &-tab {
      &-list {
        padding-right: 30px;
      }
      &--descr {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-auto-rows: minmax(auto, auto);
      }
      &-list {
        margin-bottom: 0;
      }
    }
  }
}

@media(min-width: $desktop-width) {
  .certificate__card {
    &-view {
      display: grid;
      grid-template-columns: 31% 1fr;
      grid-auto-rows: minmax(auto, auto);
      grid-gap: 0px 30px;
      padding: 0 15px;
    }
    &-action {
      grid-template-columns: 1fr;
      grid-auto-rows: calc(100vw / 5.2 + 20px);
      grid-gap: 0px;
      padding: 0;
      margin-bottom: 0;
      & > div:last-child {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
    &-img {
      height: calc(100vw / 5.2);
      margin-bottom: 10px;
    }
    &-title {
      display: none;
    }
    &-description {
      position: static;
    }
    &-info {
      padding: 30px;
    }
    &-tabs {
    }
    &-tab {
      &--descr {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-auto-rows: minmax(auto, auto);
        ul {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media(min-width: 1200px) {
  .certificate__card {
    &-action {
      grid-auto-rows: calc(1200px / 5.2 + 20px);
    }
    &-header {
      padding: 0;
    }
    &-view {
      padding: 0;
    }
    &-img {
      height: calc(1200px / 5.2);
    }
  }
}
</style>
