<template>
  <div class="payment__stepper">
    <div class="payment__stepper-tabs">
      <div
        :class="['payment__stepper-tabs-item', { 'active': !nextStep }]"
      >
          <span>
            <span
              v-if="phoneComplete"
              class="complete"
              v-html="require('@/assets/img/icons/complete.svg')"
            />
          1</span>
        Идентификация
      </div>
      <div
        :class="['payment__stepper-tabs-item', { 'active': nextStep && !paymentComplete, 'error': paymentError }]"
      >
        <span>
          <span
            v-if="paymentComplete"
            class="complete"
            v-html="require('@/assets/img/icons/complete.svg')"
          />
          <span
            v-if="paymentError"
            class="complete"
            v-html="require('@/assets/img/icons/error.svg')"
          />
        2</span>
        {{ isFree ? 'Получение' : 'Покупка' }} {{ getGood }}а
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PaymentStepper',
  props: {
    phoneComplete: {
      type: Boolean,
      required: true
    },
    paymentComplete: {
      type: Boolean,
      required: false
    },
    paymentError: {
      type: Boolean,
      required: false
    },
    nextStep: {
      type: Boolean,
      required: true
    },
    certificate: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'getGood'
    ]),
    isFree () {
      return +this.certificate.nominal === 0
    }
  }
}
</script>

<style lang="scss">
.payment__stepper {
  &-tabs {
    display: flex;
    align-items: center;
    width: 100%;
    height: 36px;
    margin-bottom: 16px;
    &-item {
      display: flex;
      align-items: center;
      width: 51%;
      background: $secondary;
      padding: 3px;
      color: $text-secondary;
      font-size: $caption;
      font-weight: 500;
      line-height: 1.2;
      text-align: left;
      border-radius: $border-radius;
      margin-right: -20px;
      &.active {
        background: $text-secondary;
        color: $primary;
      }
      &.error {
        background: $accent;
      }
      & span {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary;
        color: $text-secondary;
        font-size: $title;
        font-weight: 500;
        margin-right: 6px;
        position: relative;
        border-radius: $border-radius;
        & span.complete {
          background: $accent;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -10px;
          right: -10px;
          & svg {
            fill: $primary;
          }
        }
      }
    }
  }
}
</style>
