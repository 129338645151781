<template>
  <div class="purchase-limit">
    <img
      src="../../assets/img/limit.png"
      alt="limit-exception"
    />
    <h1>Упс...Превышен лимит покупки товара</h1>
    <p>Следующая покупка товара будет доступна с {{ nextDateAvaialable }}, с 00:00 часов.</p>
    <!-- <p>Вы можете выбрать не более 1 товара этого вида раз в
      {{ error.purchaseInterval }}
      {{ num_word(error.purchaseInterval, ['день', 'дня', 'дней']) }}.
    </p> -->

    <div class="purchase-limit__certificate">
      <div>Название:
        <span v-html="certificate.name" />
      </div>
      <div>Стоимость:
        <span>{{ certificate.nominal }}
          <app-currency :certificate="certificate" />
          <span v-if="certificate.subscription && certificate.subscription !== 'null'">
            - {{ certificate.subscription }}
          </span>
        </span>
      </div>
    </div>

    <app-button
      @click="goToCatalog"
    >
      Продолжить выбор в каталоге
    </app-button>

    <div class="purchase-limit__rules">
      <a
        href="http://okhtamall.ru/files/2021/02/edinye-pravila-mobilnogo-prilozheniya-trcz-ohta-moll-1.pdf"
        target="_blank"
      >
        Правила участия в программе
      </a>
    </div>
  </div>
</template>

<script>
import Currency from '@/components/UI/config/Currency'
import Button from '@/components/UI/buttons/Button'

export default {
  name: 'PurchaseLimit',
  components: {
    'app-currency': Currency,
    'app-button': Button
  },
  props: {
    error: {
      type: Object,
      required: true
    },
    certificate: {
      type: String,
      required: true
    }
  },
  computed: {
    nextDateAvaialable () {
      const date = new Date(this.error.nextDateAvailable)

      let dd = date.getDate()
      if (dd < 10) dd = '0' + dd

      let mm = date.getMonth() + 1
      if (mm < 10) mm = '0' + mm

      let yy = date.getFullYear()
      if (yy < 10) yy = '0' + yy

      return `${dd}.${mm}.${yy}`
    }
  },
  methods: {
    goToCatalog () {
      this.$router.push({ name: 'Catalog' })
    }
    // num_word (value, words) {
    //   value = Math.abs(value) % 100
    //   var num = value % 10

    //   if (value > 10 && value < 20) return words[2]
    //   if (num > 1 && num < 5) return words[1]
    //   if (num === 1) return words[0]

    //   return words[2]
    // }
  }
}
</script>

<style lang="scss">
.purchase-limit {
  max-width: 513px;
  padding: 0 15px;
  img {
    width: 194px;
    height: 192px;
    text-align: center;
    background-color: transparent;
  }
  h1, p, li {
    text-align: left;
  }
  h1 {
    margin: 10px 0 20px 0;
    font-size: $title;
    font-weight: 500;
  }
  p {
    margin: 0 0 20px 0;
  }
  &__certificate {
    padding: 20px 0;
    text-align: left;
    border-top: 1px solid #F3F2EA;
    & div {
      font-size: $font-size-root;
      color: $text-secondary;
      & span {
        font-weight: 500;
      }
    }
  }
  &__rules {
    margin-top: 20px;
    a {
      font-size: $caption;
      font-weight: 400;
    }
  }
}

@media (min-width: $tablet-width) {
  .purchase-limit {
    button {
      max-width: 300px;
    }
  }
}
</style>
