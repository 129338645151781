import { createStore } from 'vuex'
import auth from './modules/auth'
import payment from '@/store/modules/payment'
import paymentService from '@/store/modules/paymentService'
import config from '@/store/modules/config'
import promocodes from '@/store/modules/promocodes'
import sections from '@/store/modules/sections'
import registerModules from '@/store/modules/registerModules'

const getDefaultState = () => {
  return {
    loading: false,
    error: false,
    errorMessage: ''
  }
}

export default createStore({
  state: getDefaultState(),
  mutations: {
    'SET_LOADING' (state, data) {
      state.loading = data
    },
    'SET_ERROR' (state, data) {
      state.error = data
    },
    'SET_ERROR_MESSAGE' (state, data) {
      if (data === 'CERTIFICATE_IS_ALREADY_SOLD') {
        state.errorMessage = 'Похоже все сертификаты куплены, пожалуйста, вернитесь позднее, мы добавим новые.'
      } else if (data === 'NOT_ENOUGHT_MERCHANT_BALANCE') {
        state.errorMessage = 'Техническая ошибка, уже чиним. Пожалуйста, попробуйте завтра.'
      } else {
        state.errorMessage = 'Ошибка сервера. Повторите попытку позже.'
      }
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    resetState ({ commit }) {
      commit('RESET_STATE')
    }
  },
  getters: {
    getLoading: state => state.loading,
    getError: state => state.error,
    getErrorMessage: state => state.errorMessage
  },
  modules: {
    auth,
    payment,
    paymentService,
    config,
    promocodes,
    sections,
    registerModules
  }
})
