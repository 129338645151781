import { cardsApi } from '@/api/cards'
import { goodsApi } from '../../../../api/goods'

function translate (value, language) {
  const currency = [
    { value: '', text: 'Все' },
    { value: 'cash', text: 'За бонусы' },
    { value: 'star', text: 'За звезды' }
  ]

  if (language === 'eng') {
    const found = currency.find(el => el.value === value)
    return found ? found.text : value
  } else if (language === 'rus') {
    const found = currency.find(el => el.text === value)
    return found ? found.value : value
  }
}

// const getDefaultState = () => {
//   return {
//     body: {},
//     page: {
//       page: 0,
//       size: 16,
//       sort: ''
//     },
//     cards: [],
//     currentCard: '',
//     filters: {},
//     names: [],
//     totalElements: ''
//   }
// }

export default {
  namespaced: true,
  state () {
    return {
      body: {
        nominal: {}
      },
      page: {
        page: 0,
        size: 16,
        sort: ''
      },
      cards: [],
      currentCard: '',
      filters: {},
      // names: [],
      totalElements: ''
    }
  },
  getters: {
    getSort: state => state.page.sort,
    getCards: state => state.cards,
    getTotalElements: state => state.totalElements,
    getCurrentCard: state => state.currentCard,
    getCurrentGoods: state => state.currentGoods,
    getFilters: state => state.filters,
    // getNames: state => state.names,
    getFilter: state => state.body
  },
  mutations: {
    'SET_FILTER' (state, data) {
      if (data === undefined) state.body = { nominal: {} } // если параметр не передан, очищаем фильтр
      else state.body = { ...state.body, ...data } // если передан, добавляем переданные значения к уже существующим
    },
    'SET_SORT' (state, data) {
      state.page.sort = data
    },
    'SET_PAGE' (state, data) {
      if (data === undefined) state.page.page++ // если параметр не передан, устанавливаем следующую страницу
      else state.page.page = data // если передан, устанавливаем переданное значение
    },
    'SET_CARDS' (state, data) {
      if (data.page === 0) {
        state.cards = data.content
      } else {
        state.cards.push(...data.content)
      }
    },
    'SET_CURRENT_CARD' (state, data) {
      state.currentCard = { ...data }
    },
    'SET_CURRENT_GOODS' (state, data) {
      state.currentGoods = { ...data }
    },
    'SET_FILTERS' (state, data) {
      state.filters = data

      if (state.filters.currency) {
        state.filters.currency.unshift('')
        state.filters.currency = state.filters.currency.map(el => { return translate(el, 'eng') })
      }
    },
    // 'SET_NAMES' (state, data) {
    //   state.names = data
    // },
    'SET_TOTAL_ELEMENTS' (state, data) {
      state.totalElements = data
    }
  },
  actions: {
    // async searchFilters ({ commit }) {
    //   try {
    //     const filters = await cardsApi.searchFilters({ section: this.getters.getCurrentSection.title })
    //     commit('SET_FILTERS', {
    //       categories: [...filters.data.categories],
    //       partners: [...filters.data.partners],
    //       currency: [...filters.data.currency],
    //       subcategories: [...filters.data.subcategories],
    //       platforms: [...filters.data.platforms],
    //       names: [...filters.data.names]
    //     })
    //   } catch (err) {
    //     console.log(err)
    //     throw err
    //   }
    // },
    async searchCards ({ commit, state }, data) {
      if (state.body.currency) {
        state.body.currency = translate(state.body.currency, 'rus')
      }

      state.body.section = this.getters.getCurrentSection.title // добавляем в параметры поиска значение активного
      // раздела

      if (state.body.nominal) {
        state.body.nominal.min = state.body.nominal.min ? +state.body.nominal.min : undefined
        state.body.nominal.max = state.body.nominal.max ? +state.body.nominal.max : undefined
      }

      this.commit('SET_LOADING', true)
      this.commit('SET_ERROR', false)

      try {
        const cards = await cardsApi.search(state.page, state.body)
        commit('SET_CARDS', { content: cards.data.content, page: state.page.page })
        // commit('SET_NAMES', cards.data.names)
        commit('SET_TOTAL_ELEMENTS', cards.data.totalElements)

        commit('SET_FILTERS', {
          categories: [...cards.data.filters.categories],
          subcategories: [...cards.data.filters.subcategories],
          platforms: [...cards.data.filters.platforms],
          benefits: [...cards.data.filters.benefits],
          currency: [...cards.data.filters.currency],
          names: [...cards.data.filters.names],
          partners: [...cards.data.filters.partners]
        })
      } catch (err) {
        console.log(err)
        throw err
      } finally {
        this.commit('SET_LOADING', false)
      }
    },
    async searchCardById ({ commit }, { id }) {
      try {
        const card = await cardsApi.searchCardById(id)
        commit('SET_CURRENT_CARD', card.data)
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    async searchGoodsByCardId ({ commit }, { id }) {
      try {
        const card = await goodsApi.searchGoodsCardById(id)
        commit('SET_CURRENT_GOODS', card.data)
      } catch (err) {
        console.log(err)
        throw err
      }
    },
    setFilter ({ commit }, data) {
      commit('SET_FILTER', data)
      commit('SET_PAGE', 0)
    },
    setSort ({ commit }, data) {
      commit('SET_SORT', data)
      commit('SET_PAGE', 0)
    },
    setPage ({ commit }, data) {
      commit('SET_PAGE', data)
    }
  }
}
