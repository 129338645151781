import { paymentServiceApi } from '@/api/paymentService'
import router from '@/router'

const getDefaultState = () => ({})

export default {
  state: getDefaultState(),
  mutations: {},
  actions: {
    async pay ({ commit }, data) {
      commit('SET_ERROR', false)
      commit('SET_LOADING', true)
      try {
        const res = await paymentServiceApi.payment(data)

        window.location.replace(res.data.data.formUrl)
      } catch (err) {
        commit('SET_ERROR', true)
        commit('SET_ERROR_MESSAGE', err.response.data.message)
        console.log('err', err)
        throw err
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async complete ({ commit }, data) {
      commit('SET_ERROR', false)
      commit('SET_LOADING', true)
      try {
        const res = await paymentServiceApi.complete(data)

        commit('SET_CERTIFICATE_INFO', res.data)
      } catch (err) {
        if (err.response.data.message === 'PAYMENT_IS_NOT_CONFIRMED') {
          router.push({ name: 'Success', params: { error: 'payment' } })
        } else {
          commit('SET_ERROR', true)
          commit('SET_ERROR_MESSAGE', err.response.data.message)
        }
        console.log('err', err)
        throw err
      } finally {
        commit('SET_LOADING', false)
      }
    }
  },
  getters: {}
}
