const filters = {
  // форматируем номинал
  // если без копеек, округляем номинал до целого числа
  // если с копейками, округляем номинал до 2 знаков после точки
  sum (v) {
    let sum = parseFloat(v)
    sum = sum.toFixed(2)

    const split = sum.toString().split('.')
    if (split[1]) {
      if (split[1] === '00') {
        sum = split[0]
      }
    }

    return sum
  }
}
export default filters
