<template>
  <div class="checkbox">
    <label
      class="checkbox__input"
      :class="{
        'checked': value,
        'disabled': disabled
      }"
    >
      <input
        type="checkbox"
        :required="required"
        @change="updateInput"
        :disabled="disabled"
      >
      <transition name="fade">
        <div
          v-show="value"
          class="checkbox__input-icon"
          v-html="require('@/assets/img/icons/ok.svg')"
        />
      </transition>
    </label>
    <p class="checkbox__label">
      <slot />
    </p>
  </div>
  <transition name="expand">
    <div
      v-show="!valid"
      class="input__error"
    >
      <div class="input__error-wrapper">
        <div
          class="input__icon input__icon--attention"
          v-html="require(`@/assets/img/icons/attention.svg`)"
        />
        <span>Поле обязательно для заполнения</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    valid: true
  }),
  methods: {
    updateInput: function (event) {
      this.$emit('update:value', event.target.checked)
      this.validate(event.target.checked)
    },
    validate (e) {
      this.valid = true
      if (this.required && !e) {
        this.valid = false
      }
    }
  }
}
</script>

<style lang="scss">
.checkbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  &__input {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    heighT: 20px;
    border: 1px solid $text-secondary;
    border-radius: 50%;
    cursor: pointer;
    transition: border $transition;
    input {
      display: none;
    }
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $text-secondary;
      }
    }
    &:hover {
      border: 1px solid $text-primary;
    }
    &.checked {
      border: 1px solid $text-secondary;
    }
    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
  &__label {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 6px;
    font-size: $caption;
    line-height: $caption-line-height;
    color: $text-secondary;
    text-align: left;
  }
}
</style>
