<template>
  <button
    :class="`button button--${type} button--${size}`"
    :disabled="disabled"
  >
    <div v-if="loading" class="loader__block">
      <div
        class="spinner center"
      >
        <div
          v-for="i in 12"
          :key="i"
          class="spinner-blade spinner-blade--button"
        />
      </div>
    </div>
    <slot v-else>Купить</slot>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      required: false,
      default: 'fill',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        // return ['fill', 'outline', 'flat'].indexOf(value) !== -1
        return ['fill', 'flat'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      required: false,
      default: 'm',
      validator (value) {
        // Значение должно соответствовать одной из этих строк
        return ['m', 's'].indexOf(value) !== -1
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss">
.button {
  max-width: 100%;
  font-family: $body-font-family;
  font-weight: 500;
  white-space: nowrap;
  border: none;
  border-radius: $border-radius;
  overflow: hidden;
  cursor: pointer;
  transition: color $transition, background-color $transition, border $transition, box-shadow $transition;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
  &[disabled] {
    color: $placeholder;
    pointer-events: none;
  }
  &--s {
    width: 116px;
    height: 36px;
    font-size: $font-size-root;
    line-height: 36px;
  }
  &--m {
    width: 154px;
    height: 46px;
    font-size: $subtitle;
    line-height: 46px;
  }
  &--fill {
    width: 100%;
    color: $primary;
    background-color: $accent;
    &:hover {
      background: $accent-hover;
      box-shadow: $box-shadow-button-hover;
    }
    &[disabled] {
      background-color: $secondary;
      box-shadow: 0px 3px 16px rgba(237, 235, 235, 0.3);
    }
  }
  // &--outline {
  //   font-size: $body-1;
  //   color: $text-primary;
  //   background-color: transparent;
  //   border: 1px solid $accent-light;
  //   &:hover {
  //     box-shadow: inset 0 0 0 1px $accent-light;
  //   }
  // }
  &--flat {
    width: auto !important;
    height: auto;
    line-height: 1;
    color: $text-secondary;
    background-color: transparent;
    &:hover {
      color: $accent;
    }
  }
}
</style>
