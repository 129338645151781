<template>
  <div class="banner">
    <div v-if="bannerOne.desktopImg || bannerOne.mobileImg" class="banner__img">
      <a v-if="bannerOne.link" :href="bannerOne.link" target="_blank">
        <img v-if="bannerOne.desktopImg" :src="bannerOne.desktopImg" class="banner__desktop" alt="">
        <img v-if="bannerOne.mobileImg" :src="bannerOne.mobileImg" class="banner__mobile" alt="">
      </a>
      <template v-else>
        <img v-if="bannerOne.desktopImg" :src="bannerOne.desktopImg" class="banner__desktop" alt="">
        <img v-if="bannerOne.mobileImg" :src="bannerOne.mobileImg" class="banner__mobile" alt="">
      </template>
    </div>
    <div v-if="stepsBanner.title || stepsBanner.steps.length" class="banner__box">
      <div class="banner__top">
        <div v-if="stepsBanner.title" class="banner__title">{{ stepsBanner.title }}</div>
        <a v-if="stepsBanner.link && stepsBanner.linkText" :href="stepsBanner.link" class="banner__btn">
          {{ stepsBanner.linkText }}
        </a>
      </div>
      <template v-if="stepsBanner.steps.length">
        <div class="banner__bottom">
          <div v-for="(step, index) of stepsBanner.steps" :key="index" class="banner__terms">
            <img class="banner__terms-img"
                 :src="step.img"
                 alt="vk">
            <div class="banner__text"><span v-html="step.text"></span> <a :href="step.link" class="banner__link" v-html="step.linkText"></a></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CatalogBanner',
  computed: {
    bannerOne () {
      return {
        desktopImg: process.env.VUE_APP_BANNER_1_DESKTOP,
        mobileImg: process.env.VUE_APP_BANNER_1_MOBILE,
        link: process.env.VUE_APP_BANNER_1_LINK
      }
    },
    stepsBanner () {
      return {
        title: process.env.VUE_APP_BANNER_2_TITLE,
        link: process.env.VUE_APP_BANNER_2_BTN_LINK,
        linkText: process.env.VUE_APP_BANNER_2_BTN_TEXT,
        steps: this.getSteps()
      }
    }
  },
  methods: {
    getSteps () {
      const steps = []

      if (process.env.VUE_APP_BANNER_2_STEP_1_IMG && (process.env.VUE_APP_BANNER_2_STEP_1_TEXT || process.env.VUE_APP_BANNER_2_STEP_1_LINK_TEXT)) {
        steps.push({
          img: process.env.VUE_APP_BANNER_2_STEP_1_IMG,
          text: process.env.VUE_APP_BANNER_2_STEP_1_TEXT,
          link: process.env.VUE_APP_BANNER_2_STEP_1_LINK,
          linkText: process.env.VUE_APP_BANNER_2_STEP_1_LINK_TEXT
        })
      }

      if (process.env.VUE_APP_BANNER_2_STEP_2_IMG && (process.env.VUE_APP_BANNER_2_STEP_2_TEXT || process.env.VUE_APP_BANNER_2_STEP_2_LINK_TEXT)) {
        steps.push({
          img: process.env.VUE_APP_BANNER_2_STEP_2_IMG,
          text: process.env.VUE_APP_BANNER_2_STEP_2_TEXT,
          link: process.env.VUE_APP_BANNER_2_STEP_2_LINK,
          linkText: process.env.VUE_APP_BANNER_2_STEP_2_LINK_TEXT
        })
      }

      if (process.env.VUE_APP_BANNER_2_STEP_3_IMG && (process.env.VUE_APP_BANNER_2_STEP_3_TEXT || process.env.VUE_APP_BANNER_2_STEP_3_LINK_TEXT)) {
        steps.push({
          img: process.env.VUE_APP_BANNER_2_STEP_3_IMG,
          text: process.env.VUE_APP_BANNER_2_STEP_3_TEXT,
          link: process.env.VUE_APP_BANNER_2_STEP_3_LINK,
          linkText: process.env.VUE_APP_BANNER_2_STEP_3_LINK_TEXT
        })
      }

      if (process.env.VUE_APP_BANNER_2_STEP_4_IMG && (process.env.VUE_APP_BANNER_2_STEP_4_TEXT || process.env.VUE_APP_BANNER_2_STEP_4_LINK_TEXT)) {
        steps.push({
          img: process.env.VUE_APP_BANNER_2_STEP_4_IMG,
          text: process.env.VUE_APP_BANNER_2_STEP_4_TEXT,
          link: process.env.VUE_APP_BANNER_2_STEP_4_LINK,
          linkText: process.env.VUE_APP_BANNER_2_STEP_4_LINK_TEXT
        })
      }

      return steps
    }
  }
}
</script>

<style scoped lang="scss">
.banner {
  padding: 0 8px 35px;

  &__img {
    width: 100%;
    margin-bottom: 24px;
    border-radius: $border-radius;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: contain;
      background: transparent;
    }
  }

  &__desktop {
    display: none;
  }

  &__top {
    margin-bottom: 35px;
  }

  &__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 18px;
    text-align: center;
  }

  &__btn {
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: $banner-accent-color;
    height: 40px;
    width: 100%;
    border: 1px solid $banner-accent-color;
    border-radius: 4px;
    cursor: pointer;
    background: transparent;
    transition: $transition;

    &:hover {
      color: $banner-accent-color;
      box-shadow: 0 3px 16px rgba(#F37D10, 0.6);
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }

  &__terms {
    display: flex;
    width: 100%;
    margin-bottom: 42px;
  }

  &__terms-img {
    background: transparent;
    margin-right: 20px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  &__link {
    color: $banner-accent-color;
    text-decoration: underline;
    &:hover {
      color:$banner-accent-color;
    }
  }

}

@media(min-width: $desktop-width) {
  .banner {
    padding: 0 8px 55px;
    &__top {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 0;
    }

    &__btn {
      width: 21%;
    }

    &__bottom {
      margin-top: 50px;
      flex-direction: row;
      justify-content: space-between;
    }

    &__terms {
      width: auto;
      max-width: 23%;
      margin-bottom: 0;
      display: block;
    }

    &__terms-img {
      margin-bottom: 20px;

      &:first-child {
        margin-bottom: 24px;
      }
    }
  }
}

@media (min-width: $tablet-width) {
  .banner {
    &__desktop {
      display: block;
    }

    &__mobile {
      display: none;
    }
  }
}
</style>
