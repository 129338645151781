export default {
  methods: {
    load (el, path) {
      if (!el) return

      el.classList.add('image', '-loading')

      const image = new Image()
      image.onload = () => {
        this.render(el)
        image.classList.add('image')
        image.setAttribute('alt', 'certificate')
        el.appendChild(image)
      }

      image.onerror = () => {
        this.render(el)
      }

      image.src = path
    },
    render (el) {
      el.classList.remove('-loading')
    }
  }
}
