<template>
  <div class="catalog">
    <div
      v-if="getError && showError"
      class="catalog__error">
      <div
        class="error"
      >
        {{ getErrorMessage }}
      </div>
    </div>
    <div class="catalog__title" v-if="iconPath && iconPath.length">
      <img :src="iconPath" alt="">
      <h2>{{ title }}</h2>
    </div>

    <app-banner />

    <app-section v-if="getSections && getSections.length > 1"/>

    <template v-if="getCurrentSection">
      <app-filter :key="getCurrentSection.title"/>

      <app-sort :key="getCurrentSection.title"/>

      <app-list :key="getCurrentSection.title"/>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Section from '@/components/catalog/CatalogSection'
import Filter from '@/components/catalog/CatalogFilter'
import Sort from '@/components/catalog/CatalogSort'
import List from '@/components/catalog/CatalogList'
import Banner from '@/components/catalog/CatalogBanner'

export default {
  name: 'Catalog',
  components: {
    'app-filter': Filter,
    'app-sort': Sort,
    'app-list': List,
    'app-section': Section,
    'app-banner': Banner
  },
  data: () => ({
    showError: false
  }),
  watch: {
    'getError' (val) {
      if (val) {
        this.showError = true
      }
      setTimeout(() => {
        this.showError = false
      }, 5000)
    }
  },
  computed: {
    ...mapGetters([
      'getSections',
      'getCurrentSection',
      'getError',
      'getErrorMessage'
    ]),
    iconPath () {
      return process.env.VUE_APP_LOGO
    },
    title () {
      return process.env.VUE_APP_TITLE
    }
  }
}
</script>

<style lang="scss">
.catalog {
  text-align: left;

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    box-shadow: $box-shadow;
    margin-top: -38px;
    padding: 10px;

    img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
    }

    h2 {
      display: inline-block;
    }
  }

  &__error {
    position: fixed;
    top: 10px;
    z-index: 1000;
    width: 100%;
    left: 0;
  }
  .error {
    color: $accent;
    font-weight: 500;
    border: 1px solid $accent;
    padding: 5px;
    max-width: 100%;
    width: 500px;
    margin: 0 auto;
    background: #ffffff;
  }

  &__banner {
    width: 100%;
    margin-bottom: 24px;

    img {
      width: 100%;
      object-fit: contain;
      background: #FFFFFF;
    }
  }

  &__desktop {
    display: none;
  }
}

@media (min-width: $tablet-width) {
  .catalog {
    &__desktop {
      display: block;
    }

    &__mobile {
      display: none;
    }
  }
}
</style>
