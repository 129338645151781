import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import clickOutsideDirective from './directives/clickOutside'
import scrollDirective from './directives/scroll'
import filters from './filters/index'

// const app = createApp(App).use(store).use(router).mount('#app')
const app = createApp(App)

app.directive('click-outside', clickOutsideDirective)
app.directive('scroll', scrollDirective)

app.config.globalProperties.$filters = filters

app.use(store)
app.use(router)
app.mount('#BMSApp')
