<template>
  <nav class="catalog-section">
    <h2>Выберите раздел:</h2>
    <ul>
      <li
        v-for="(section, i) in getSections"
        :key="i"
        :class="{ 'active': getCurrentSection.title === section.title }"
        @click="update(section)"
      >
        <div v-html="require(`@/assets/img/icons/${section.icon}.svg`)" />
        {{ section.title }}
        <span>&nbsp;{{ section.totalElements }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CatalogSections',
  computed: {
    ...mapGetters([
      'getSections',
      'getCurrentSection'
    ])
  },
  methods: {
    ...mapActions([
      'setCurrentSection',
      'searchCards'
    ]),
    update (section) {
      this.setCurrentSection(section)
    }
  }
}
</script>

<style lang="scss">
$mobile-line-height: 20px;
$desktop-line-height: 25px;

.catalog-section {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 15px;
  h2 {
    display: inline-block;
    margin: 0 15px 5px 0;
    font-size: $title;
    line-height: $mobile-line-height;
    font-weight: 500;
    white-space: nowrap;
  }
  ul {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
      font-size: $subtitle;
      line-height: $mobile-line-height;
      font-weight: 500;
      white-space: nowrap;
      transition: color $transition;
      cursor: pointer;
      div {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 3px 4px 0;
        svg {
          width: $mobile-line-height;
          height: auto;
          transition: fill $transition;
        }
      }
      span {
        color: $placeholder;
      }
      &.active,
      &:hover {
        color: $accent;
        div {
          svg {
            fill: $accent;
          }
        }
      }
    }
  }
}

@media(min-width: $tablet-width) {
}

@media(min-width: $desktop-width) {
  .catalog-section {
    flex-wrap: nowrap;
    margin-bottom: 0;
    h2 {
      margin: 0 30px 20px 0;
      line-height: $desktop-line-height;
    }
    ul {
      li {
        margin-right: 30px;
        margin-bottom: 20px;
        line-height: $desktop-line-height;
        div {
          svg {
            width: $desktop-line-height;
          }
        }
      }
    }
  }
}
</style>
