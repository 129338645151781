<template>
  <div class="button-group">
    <button
      type="button"
      :class="[
        `button-group__button`,
        {
          'active': val.value === value,
          'disabled': disabled
        }
      ]"
      v-for="val in values"
      :key="val.value"
      @click="update(val.value)"
      :disabled="disabled"
    >
      {{ val.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonToggle',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    values: [
      { value: true, title: 'Да' },
      { value: false, title: 'Нет' }
    ]
  }),
  mounted () {
    this.selected = this.default
  },
  methods: {
    update (val) {
      this.$emit('update:value', val)
    }
  }
}
</script>

<style lang="scss">
.button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: $border-radius;
  overflow: hidden;
  &__button {
    flex-grow: 1;
    height: 46px;
    max-width: 100%;
    font-weight: 500;
    font-size: $subtitle;
    line-height: 1;
    color: $text-primary;
    background-color: $secondary;
    box-shadow: none;
    outline: none;
    border: none;
    cursor: pointer;
    transition: color $transition, background-color $transition, box-shadow $transition;
    &:hover {
      background-color: #D7D7D7;
      box-shadow: 0px 3px 16px rgba(237, 235, 235, 0.6);
    }
    &.active {
      color: $primary;
      background-color: $accent;
      box-shadow: $box-shadow-error;
    }
    &.disabled {
      pointer-events: none;
    }
  }
}
</style>
