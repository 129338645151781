<template>
  <div class="ui-kit">
    <h2>Search</h2>
    <div class="ui-kit__block">
      <app-autocomplele
        v-model:value="search"
        :options="['test', 'Item', 'item', 'item1']"
        placeholder="Не выбрано..."
      />
    </div>

    <h2>Selects</h2>
    <div class="ui-kit__block">
      <app-select
        v-model:value="select"
        :options="['item1item1it em1item1item1item1item1item1item1item1', 'item2', 'item3', 'item4', 'item5', 'item6']"
        type="category"
        label="Категория"
      />

      <app-select
        v-model:value="select"
        default
        :options="['item1item1item1item1item1item1item1item1item1item1', 'item2', 'item3', 'item4', 'item5', 'item6']"
        type="nominal"
      />

      <app-select
        v-model:value="select"
        default
        :options="['item1', 'item2', 'item3']"
        type="sort"
      />
    </div>

    <h2>Inputs</h2>
    <div class="ui-kit__block">
      <app-input
        v-model:value="phone"
        v-model:valid="valid.phone"
        type="phone"
        label="На номер будет отправлено SMS с кодом"
        placeholder="+7 ("
        required
      />

      <app-input
        v-model:value="code"
        type="code"
        label="Код из SMS"
        placeholder="*****"
        required
      />

      <app-input
        v-model:value="email"
        type="email"
        label="Электронная почта для отправки чека и сертификата"
        required
        @enter="enterClick()"
        :focus="focus"
      />

      <app-input
        v-model:value="nominal"
        type="nominal"
        label="Диапазон стоимости"
        placeholder="0 ₽"
      />
    </div>

    <h2>Buttons</h2>
    <div class="ui-kit__block">
      <app-button
        type="fill"
        size="m"
      >
        Показать 64
      </app-button>

      <app-button
        type="fill"
        size="m"
        disabled
      >
        Показать 64
      </app-button>

      <app-button
        type="fill"
        size="s"
      >
        Купить
      </app-button>

      <!-- <app-button
        type="outline"
        size="s"
      >
        Скопировать код
      </app-button>

      <app-button
        type="outline"
        size="s"
        disabled
      >
        Скопировать код
      </app-button> -->

      <app-button
        type="flat"
      >
        Сбросить
      </app-button>

      <app-button-arrow left />
      <app-button-arrow top />
      <app-button-arrow bottom />

      <app-tooltip
        icon="Tooltip"
        :size="[22, 22]"
        message="До этой даты Вы можете использовать код у Партнера"
      />
    </div>

    <div class="ui-kit__block">
      <div style="width: 260px; margin-right: 12px;">
        <app-button-group
          v-model:value="group"
          default="polyester"
        />
      </div>

      <div style="width: 290px; margin-right: 12px;">
        <app-button-copy value="0D4338F" />
      </div>

      <div style="width: 290px; margin-right: 12px;">
        <app-button-copy value="0D4338F456FGD" />
      </div>

      <div style="width: 290px;">
        <app-button-copy value="0D4338F456FGD650521JY2126F845S" />
      </div>
    </div>

    <h2>Links</h2>
    <div class="ui-kit__block" style="font-weight: 700;">
      <app-link link="https://central-ppk.ru/new">
        https://central-ppk.ru/new
      </app-link>
    </div>

    <h2>Checkbox</h2>
    <div class="ui-kit__block" style="width: 295px;">
      <app-checkbox
        v-model:value="checkbox"
        required
      >
        Я принимаю
        <app-link link="https://central-ppk.ru/new">
          условия покупки
        </app-link>
        электронных сертификатов и выражаю свое
        <app-link link="https://central-ppk.ru/new">
          Согласие с использованием персональных данных
        </app-link>
        в целях обработки настоящего обращения и согласие с
        <app-link link="https://central-ppk.ru/new">
          Политикой в отношении обработки и защиты персональных данных
        </app-link>
      </app-checkbox>
    </div>

    <h2>Cards catalog</h2>
    <div class="ui-kit__block">
      <div class="ui-kit__status ui-kit__status--s">
        <img :src="image" alt="placeholder" />
        <!--   для правильной работы status необходимо добавить родителю position: relative     -->
        <div class="status status--s">NEW</div>
      </div>
      <div class="ui-kit__status ui-kit__status--m">
        <img :src="image" alt="placeholder" />
        <!--  для правильной работы status необходимо добавить родителю position: relative     -->
        <div class="status status--m">Промо</div>
      </div>
    </div>

    <h2>Loader</h2>
    <div class="ui-kit__block">
      <app-loader :status="true" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI/buttons/Button'
import ButtonArrow from '@/components/UI/buttons/ButtonArrow'
import Link from '@/components/UI/links/Link'
import Select from '@/components/UI/selects/Select'
import Input from '@/components/UI/inputs/Input'
import Autocomplete from '@/components/UI/selects/Autocomplete'
import Loader from '@/components/UI/Loader'
import Tooltip from '@/components/UI/buttons/ButtonTooltip'
import Checkbox from '@/components/UI/inputs/Checkbox'
import ButtonGroup from '@/components/UI/buttons/ButtonGroup'
import ButtonCopy from '@/components/UI/buttons/ButtonCopy'

export default {
  name: 'UI-Kit',
  components: {
    'app-button': Button,
    'app-button-arrow': ButtonArrow,
    'app-link': Link,
    'app-select': Select,
    'app-input': Input,
    'app-autocomplele': Autocomplete,
    'app-loader': Loader,
    'app-tooltip': Tooltip,
    'app-checkbox': Checkbox,
    'app-button-group': ButtonGroup,
    'app-button-copy': ButtonCopy
  },
  data: () => ({
    select: '',
    search: '',
    phone: '',
    code: '',
    email: '',
    nominal: '',
    checkbox: false,
    group: true,
    valid: {
      phone: true
    },
    focus: false
  }),
  computed: {
    image () {
      const images = require.context('../assets/img/', false, /\.png$/)
      return images('./' + 'placeholder' + '.png')
    }
  },
  mounted () {
    setTimeout(() => {
      this.focus = true
    }, 2000)
  },
  methods: {
    enterClick () {
      console.log('enter')
    }
  }
}
</script>

<style lang="scss">
.ui-kit {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 30px;
    text-align: left;
  }
  &__block {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .button {
      width: 154px;
      margin-right: 15px;
    }
    .button-arrow {
      margin-right: 15px;
    }
    .select {
      width: 300px;
      margin-right: 15px;
    }
    .input {
      width: 300px;
      margin-right: 15px;
    }
  }
  &__status {
    position: relative;
    margin-right: 15px;
    img {
      width: 100%;
      height: auto;
    }
    &--s {
      width: 142px;
      height: 90px;
    }
    &--m {
      width: 290px;
      height: 180px;
    }
  }
}
</style>
