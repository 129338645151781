<template>
  <button
    class="button-scroll"
    v-scroll="onScroll"
    v-show="scrollButton"
    @click="toTop"
  >
    <div
      class="button-scroll__icon"
      v-html="require(`@/assets/img/icons/arrow.svg`)"
    />
  </button>
</template>

<script>
export default {
  name: 'ButtonScroll',
  data () {
    return {
      scrollButton: false
    }
  },
  watch: {
    visible () {
      this.scrollButton = true
    }
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scrollButton = top > 100
    },
    toTop () {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style lang="scss">
  .button-scroll {
    position: fixed;
    bottom: 20px;
    right: 30px;
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: $accent;
    outline: none;
    border: none;
    border-radius: 50%;
    box-shadow: $box-shadow-error;
    cursor: pointer;
    transition: background-color $transition, box-shadow $transition;
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: rotate(90deg);
      svg {
        width: 12px;
        height: 11px;
        fill: $primary;
      }
    }
    &:hover {
      background-color: $accent-hover;
      box-shadow: $box-shadow-button-hover;
    }
  }

  @media(min-width: $desktop-width) {
    .button-scroll {
      width: 50px;
      height: 50px;
      &__icon {
        svg {
          width: 20px;
          height: 17px;
        }
      }
    }
  }
</style>
