<template>
  <div class="catalog-sort">
    <h1 v-if="!getSections.length > 1">
      {{ getGood }}ы
      <span>{{ getTotalElements }}</span>
    </h1>

    <div class="catalog-sort__form">
      <app-select
        type="sort"
        v-model:value="sortValue"
        :options="sortValues"
      />
      <app-autocomplete
        v-model:value="searchValue"
        :options="getNamesAndPartners"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Select from '@/components/UI/selects/Select'
import Autocomplete from '@/components/UI/selects/Autocomplete'

export default {
  name: 'CatalogSort',
  components: {
    'app-select': Select,
    'app-autocomplete': Autocomplete
  },
  data: () => ({
    searchValue: ''
  }),
  computed: {
    ...mapGetters([
      'getSections',
      'getCurrentSection',
      'getGood'
    ]),
    storePath () {
      return `${this.getCurrentSection.title}/cards`
    },
    getNames () {
      return this.$store.getters[`${this.storePath}/getFilters`].names
    },
    getPartners () {
      return this.$store.getters[`${this.storePath}/getFilters`].partners
    },
    getNamesAndPartners () {
      return this.getNames && this.getPartners ? this.getNames.concat(this.getPartners) : []
    },
    getTotalElements () {
      return this.$store.getters[`${this.storePath}/getTotalElements`]
    },
    getSort () {
      return this.$store.getters[`${this.storePath}/getSort`]
    },
    sortData () {
      return [
        { value: 'sort,ASC', title: 'По рекомендации' },
        { value: 'currency,DESC', title: 'Сначала фирменные товары' },
        { value: 'name,ASC', title: `По ${this.getGood}у А-Я` },
        { value: 'partner,ASC', title: 'По партнеру А-Я' },
        { value: 'nominal,DESC', title: 'По стоимости &#8595;' },
        { value: 'nominal,ASC', title: 'По стоимости &#x2191' }
      ]
    },
    sortValues () {
      return this.sortData
        .filter(el => this.getCurrentSection.sortFields.includes(el.value))
        .map(el => { return el.title })
    },
    sortValue: {
      get () {
        return this.getSort ? this.sortData.find(el => el.value === this.getSort).title : ''
      },
      set (newVal) {
        this.$store.dispatch(`${this.storePath}/setSort`, this.sortData.find(el => el.title === newVal).value)
        this.$store.dispatch(`${this.storePath}/searchCards`)
      }
    }
  },
  watch: {
    searchValue () {
      this.$store.dispatch(`${this.storePath}/setFilter`, { name: this.searchValue })
      this.$store.dispatch(`${this.storePath}/searchCards`)
    }
  }
}
</script>

<style lang="scss">
.catalog-sort {
  padding: 30px 15px;
  h1 {
    display: inline-block;
    margin: 0 0 16px;
    font-size: $title;
    font-weight: 500;
    text-transform: capitalize;
    span {
      color: $text-secondary;
    }
  }
  .select-wrapper {
    margin: 0 0 16px;
  }
}

@media(min-width: $tablet-width) {
  .catalog-sort {
    padding: 40px 15px 36px;
    h1 {
      margin: 0 0 26px;
    }
    .select-wrapper {
      margin: 0;
    }
    &__form {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      & > div:first-child {
        order: 2;
        margin-left: auto;
      }
      & > div:last-child {
        order: 1;
        max-width: 585px;
        /*margin-right: 30px;*/
      }
    }
  }
}

@media(min-width: 1200px) {
  .catalog-sort {
    padding: 40px 0 36px;
  }
}
</style>
