const getDefaultState = () => {
  return {
    good: '',
    currency: '',
    cardType: '',
    cardMark: '',
    programm: '',
    appleMerchantId: '',
    appleMerchantName: '',
    payservice: true,
    paymentType: 'default',
    rewardType: ''
  }
}

export default {
  state: getDefaultState(),
  mutations: {
    'SET_CONFIG' (state, data) {
      state.good = process.env.VUE_APP_GOOD === 'CERTIFICATE' ? 'сертификат' : 'товар'
      state.currency = process.env.VUE_APP_CURRENCY.toLowerCase()
      state.cardType = process.env.VUE_APP_CARD_TYPE.toLowerCase()
      state.cardMark = process.env.VUE_APP_CARD_MARK.toLowerCase()
      state.programm = process.env.VUE_APP_PROGRAMM.toLowerCase()
      state.appleMerchantId = process.env.VUE_APP_APPLE_MERCHANT_ID
      state.appleMerchantName = process.env.VUE_APP_APPLE_MERCHANT_NAME
      state.googleMerchantId = process.env.VUE_APP_GOOGLE_MERCHANT_ID
      state.googleMerchantName = process.env.VUE_APP_GOOGLE_MERCHANT_NAME
      state.paymentType = process.env.VUE_APP_PAYMENT_TYPE || 'default'
      state.rewardType = process.env.VUE_APP_REWARD_TYPE || 'BONUS'
      state.postMarkCurrentCert = process.env.VUE_APP_POST_MARK_CURR_SERT || ''
      state.postMarkCurrentCertIs = process.env.VUE_APP_POST_MARK_CURR_SERT_IS || 'None'
      state.postMarkCurrentCertIsUp = process.env.VUE_APP_POST_MARK_CURR_SERT_IS_UP || 'None'
    },
    'SET_PAYSERVICE' (state, data) {
      state.payservice = data !== 'false'
    }
  },
  getters: {
    getGood: state => state.good,
    getCurrency: state => state.currency,
    getCardType: state => state.cardType,
    getCardMark: state => state.cardMark,
    getProgramm: state => state.programm,
    getAppleMerchantId: state => state.appleMerchantId,
    getAppleMerchantName: state => state.appleMerchantName,
    getGoogleMerchantId: state => state.googleMerchantId,
    getGoogleMerchantName: state => state.googleMerchantName,
    getPayservice: state => state.payservice,
    getPaymentType: state => state.paymentType,
    getRewardType: state => state.rewardType,
    getPostMarkCurrentCert: state => state.postMarkCurrentCert,
    getPostMarkCurrentCertIs: state => state.postMarkCurrentCertIs,
    getPostMarkCurrentCertIsUp: state => state.postMarkCurrentCertIsUp,
    getRewardTitle: state => {
      if (state.rewardType === 'CASHBACK') return 'Кешбэк'
      else return 'Бонусы'
    }
  }
}
